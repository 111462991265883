/// Pages

import Dashboard from "../jsx/pages/Dashboard";
import CourseForm from "../jsx/pages/map/CourseForm";
import CourseList from "../jsx/pages/map/CourseList";
import MapForm from "../jsx/pages/map/MapForm";
import MapList from "../jsx/pages/map/MapList";
import MemberForm from "../jsx/pages/member/MemberForm";
import MemberList from "../jsx/pages/member/MemberList";
import GamePlayForm from "../jsx/pages/settings/GamePlayForm";
import GamePlaySettings from "../jsx/pages/settings/GamePlaySettings";
import PlayerForm from "../jsx/pages/tournament/PlayerForm";
import PlayerList from "../jsx/pages/tournament/PlayerList";
import TournamentForm from "../jsx/pages/tournament/TournamentForm";
import TournamentHandicapPlay from "../jsx/pages/tournament/TournamentHandicapPlay";
import TournamentList from "../jsx/pages/tournament/TournamentList";
import TournamentMarking from "../jsx/pages/tournament/TournamentMarking";
import UserForm from "../jsx/pages/user/UserForm";
import UserList from "../jsx/pages/user/UserList";

const routes =  [
    /// Dashboard
    { url: "", component: Dashboard, regex: 'dashboard', name: "dashboard" },
    { url: "dashboard", component: Dashboard, regex: 'dashboard', name: "dashboard" },
    { url: "member/:type/:id?", component: MemberForm, regex: 'member', name: "member" },
    { url: "member", component: MemberList, regex: 'member', name: "member" },
    { url: "user/:type/:id?", component: UserForm, regex: 'user', name: "user" },
    { url: "user", component: UserList, regex: 'user', name: "user" },
    { url: "map/course/:mapId/:type/:id?", component: CourseForm, regex: 'course', name: "course" },
    { url: "map/course/:mapId", component: CourseList, regex: 'course', name: "course" },
    { url: "map/:type/:id?", component: MapForm, regex: 'map', name: "map" },
    { url: "map", component: MapList, regex: 'map', name: "map" },
    { url: "tournament/:tName/:tId/handicap/", component: TournamentHandicapPlay, regex: 'handicap', name: "handicap" },
    { url: "tournament/:tName/:tId/marking/", component: TournamentMarking, regex: 'marking', name: "marking" },
    { url: "tournament/:tName/:tId/player/:type/:id?", component: PlayerForm, regex: 'player', name: "player" },
    { url: "tournament/:tName/:tId/player", component: PlayerList, regex: 'player', name: "player" },
    { url: "tournament/:type/:id?", component: TournamentForm, regex: 'tournament', name: "tournament" },
    { url: "tournament", component: TournamentList, regex: 'tournament', name: "tournament" },
    { url: "settings/game", component: GamePlaySettings, regex: 'settings', name: "gamePlaySettings" },
    { url: "settings/game/:type/:gameType/:id?", component: GamePlayForm, regex: 'settings', name: "gamePlaySettings" },
    // { url: "settings/map/:type/:slug?", component: RiderForm, regex: 'map', name: "map" },
    // { url: "settings/course", component: RiderForm, regex: 'course', name: "course" },
    // { url: "settings/course/:type/:slug?", component: RiderForm, regex: 'course', name: "course" },
];

export default routes;