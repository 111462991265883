/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useRef, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
/// Link
import { Link, useHistory } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { companyName } from "../../../utils/info";
import { translate } from "../../../utils/translate";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartSimple, faFlagCheckered, faGears, faMountain, faUser, faUsers } from '@fortawesome/free-solid-svg-icons'

/// Image
//import profile from "../../../images/profile/pic1.jpg";
//import plus from "../../../images/plus.png";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = (props) => {
  const lang = props.lang;
  const role = props.auth.auth.data.role;
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
  } = useContext(ThemeContext);
  const history = useHistory();
  const [path, setPath] = useState("");
  const [pathHeader, setPathHeader] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const boxRef = useRef(null);

  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var navBackdrop = document.querySelector(".navigation-backdrop-control");
    var rightContainer = document.querySelector(".header-right-container");
    // var aaa = document.querySelector("#main-wrapper");
    // function toggleFunc() {
    //   return aaa.classList.toggle("menu-toggle");
    // }
    // btn.addEventListener("click", toggleFunc);

    btn.addEventListener("click", () => toggleMenuFunc());
    navBackdrop.addEventListener("click", () => toggleMenuFunc(true));
    rightContainer.addEventListener("click", () => toggleMenuFunc(true));
  }, []);
  
  useEffect(() => {
    const body = document.querySelector('body');
    body.style.overflow = isOpen ? 'hidden' : 'visible';
  }, [isOpen])

  const toggleMenuFunc = (forceClose = false) => {
    var aaa = document.querySelector("#main-wrapper");
    var aaabackdrop = document.querySelector(".navigation-backdrop-control");
    // console.log(aaa.classList.contains("menu-toggle"), 'check');
    if (forceClose) {
      aaabackdrop.classList.add('d-none');
      return aaa.classList.remove("menu-toggle");
    }
    if (aaa.classList.contains("menu-toggle")) {
      aaabackdrop.classList.add('d-none');
      return aaa.classList.remove("menu-toggle");
      // return aaa.classList.toggle("menu-toggle");
    } else {
      aaabackdrop.classList.remove('d-none');
      return aaa.classList.add("menu-toggle");
    }
  }
  let scrollPosition = useScrollPosition();
  /// Path
  // path = path.split("/");
  // path = path[path.length - 1];

  useEffect(() => {
    history.listen((location) => {
      setPathHeader(location.pathname.split('/')[1]);

      // console.log(location.pathname.split('/')[1])
      // console.log(location.pathname, settings, 'path', settings.indexOf(location.pathname));
      setPath(location.pathname);
      toggleMenuFunc(true);
    });
    if (!path) {
      setPath(history.location.pathname);
    }
    if (!pathHeader) {
      setPathHeader(history.location.pathname.split('/')[1]);
    }
  }, [history]);

  useEffect(() => {
    // console.log("refresh", pathHeader)
  }, [pathHeader])
  /// Active menu
  let
    member = ["member",],
    // dashboard = ["dashboard",],
    user = ["user",],
    tournament = ["tournament",],
    settings = ["settings",],
    map = ["map",]
    ;
  return (
    <div
      style={{zIndex: "10"}}
      className={`deznav ${iconHover} ${sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
        ? scrollPosition > 120
          ? "fixed"
          : ""
        : ""
        }`}
      ref={boxRef}
    >
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu" id="menu">
          <li className={`${path === "/" || path.match(/(dashboard)+/i) ? "mm-active" : ""}`}>
            <Link className="ai-icon" to="/dashboard" >
              {/* <i className="flaticon-025-dashboard"></i> */}
              <FontAwesomeIcon icon={faChartSimple} className="nav-icon" />
              {/* <i className="fa-solid fa-chart-simple"></i> */}
              <span className="nav-text">{translate(lang, 'dashboard')}</span>
            </Link>
          </li>
          {['admin', 'master'].includes(role) ? <>
            <li className={`${member.indexOf(pathHeader) > -1 ? "mm-active" : ""}`}>
              <Link className="has-arrow" to="#" >
                <FontAwesomeIcon icon={faUsers} className="nav-icon" />
                <span className="nav-text">{translate(lang, 'member')}</span>
              </Link>
              <ul className={`mm-collapse ${member.indexOf(pathHeader) > -1 ? "mm-show" : ""}`} >
                <li><Link className={`${path.match(/(member)+/i) ? "mm-active" : ""}`} to="/member">{translate(lang, 'list')}</Link></li>
                {/* <li><Link className={`${path === "/member-policy" ? "mm-active" : ""}`} to="/member-policy">{translate(lang, 'policy')}</Link></li> */}
              </ul>
            </li>
            <li className={`${user.indexOf(pathHeader) > -1 ? "mm-active" : ""}`}>
              <Link className="has-arrow" to="#">
                <FontAwesomeIcon icon={faUser} className="nav-icon" />
                <span className="nav-text">{translate(lang, 'user')}</span>
              </Link>
              <ul className={`mm-collapse ${user.indexOf(pathHeader) > -1 ? "mm-show" : ""}`} >
                <li><Link className={`${path.match(/(user)+/i) ? "mm-active" : ""}`} to="/user">{translate(lang, 'list')}</Link></li>
              </ul>
            </li>
            <li className={`${map.indexOf(pathHeader) > -1 ? "mm-active" : ""}`}>
              <Link className="has-arrow" to="#">
                <FontAwesomeIcon icon={faMountain} className="nav-icon" />
                <span className="nav-text">{translate(lang, 'map')}</span>
              </Link>
              <ul className={`mm-collapse ${map.indexOf(pathHeader) > -1 ? "mm-show" : ""}`} >
                <li><Link className={`${path.match(/(map)+/i) ? "mm-active" : ""}`} to="/map">{translate(lang, 'list')}</Link></li>
              </ul>
            </li>
            <li className={`${tournament.indexOf(pathHeader) > -1 ? "mm-active" : ""}`}>
              <Link className="has-arrow" to="#">
                <FontAwesomeIcon icon={faFlagCheckered} className="nav-icon" />
                <span className="nav-text">{translate(lang, 'tournament')}</span>
              </Link>
              <ul className={`mm-collapse ${tournament.indexOf(pathHeader) > -1 ? "mm-show" : ""}`} >
                <li><Link className={`${path.match(/(tournament)+/i) ? "mm-active" : ""}`} to="/tournament">{translate(lang, 'list')}</Link></li>
              </ul>
            </li>
            <li className={`${settings.includes(pathHeader) ? "mm-active" : ""}`}>
              <Link className="has-arrow" to="#">
                <FontAwesomeIcon icon={faGears} className="nav-icon" />
                <span className="nav-text">{translate(lang, 'settings')}</span>
              </Link>
              <ul className={`mm-collapse ${settings.includes(pathHeader) ? "mm-show" : ""}`} >
                <li><Link className={`${path.match(/(game)+/i) ? "mm-active" : ""}`} to="/settings/game">{translate(lang, 'gamePlay')}</Link></li>
              </ul>
            </li>
          </> : <>
            <li className={`${tournament.indexOf(pathHeader) > -1 ? "mm-active" : ""}`}>
              <Link className="has-arrow" to="#">
                <FontAwesomeIcon icon={faFlagCheckered} className="nav-icon" />
                <span className="nav-text">{translate(lang, 'tournament')}</span>
              </Link>
              <ul className={`mm-collapse ${tournament.indexOf(pathHeader) > -1 ? "mm-show" : ""}`} >
                <li><Link className={`${path.match(/(tournament)+/i) ? "mm-active" : ""}`} to="/tournament">{translate(lang, 'list')}</Link></li>
              </ul>
            </li>
          </>
          }
        </MM>
        <div className="copyright">
          <p><strong>{companyName}</strong> © 2023 All Rights Reserved</p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth, i18n } = state;
  return {
    lang: i18n.lang,
    auth: auth,
  };
};

export default connect(mapStateToProps)(SideBar);
