import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { getTournamentById, updateTournamentMarks, updateTournamentStatus } from "../../../services/ApiService";
import { checkingValueWithFormula, promptMessage, showLoader } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import { DataGrid } from '@mui/x-data-grid';

const TournamentHandicapPlay = (props) => {
    const lang = props.lang;
    const { tName, tId } = useParams();
    const history = useHistory();
    const [tournamentDetails, setTournamentDetails] = useState({
        formula: ''
    })
    const [tournamentStatus, setTournamentStatus] = useState("");
    const [list, setList] = useState({
        items: [],
        players: [],
        headers: [
            { field: 'hole_no', headerName: translate(lang, 'holeNo'), flex: 0, minWidth: 50, restricted: [], },
            {
                field: 'distance', headerName: translate(lang, 'distance'), flex: 0.5, minWidth: 200, restricted: [], renderCell: (params) => {
                    let distances = params.row.distance;
                    return distances.map(dist => (
                        <span className="p-2" style={{ backgroundColor: dist.color }}><span className="box-color-text">{dist.distance}</span></span>
                    ))
                }
            },
            { field: 'par', headerName: translate(lang, 'par'), flex: 0, minWidth: 100, restricted: [], },
            { field: 'difficulty', headerName: translate(lang, 'index'), flex: 0.5, minWidth: 150, restricted: [], },
            // { field: 'actions', type:'actions', headerName: translate(lang, 'action'), flex: 2, minWidth: 300, restricted: ['agent', 'player'], getActions: (params) => [
            //     <Link to={`/tournament/${params.row.title}/${params.id}/player`} title={translate(lang, "players")} className=" btn btn-sm btn-info cursor-pointer"><FontAwesomeIcon icon={faPeopleGroup}></FontAwesomeIcon></Link>,
            //     <button onClick={() => startHandicap(params.id)} title={translate(lang, "startHandicap")} className="btn btn-sm btn-secondary cursor-pointer"><FontAwesomeIcon icon={faHand}></FontAwesomeIcon></button>,
            //     <button onClick={() => startTournament(params.id)} title={translate(lang, "startTournament")} className="btn btn-sm btn-primary cursor-pointer"><FontAwesomeIcon icon={faFlagCheckered}></FontAwesomeIcon></button>,
            //     <Link to={`/tournament/edit/${params.id}`} title={translate(lang, "save")} className=" btn btn-sm btn-warning cursor-pointer"><FontAwesomeIcon icon={faMarker}></FontAwesomeIcon></Link>,
            //     <button onClick={() => removeItem(params.id)} title={translate(lang, "delete")} className="btn btn-sm btn-danger cursor-pointer"><FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon></button>,
            // ]},
        ],
    });

    useEffect(() => {
        getList()
    }, [])

    async function getList() {
        let res = await getTournamentById(tId);
        if (res?.status === 'success') {
            if (!res.data.handicap_play_off) {
                promptMessage(lang, 'error', translate(lang, 'noHandicapPlayOff'));
                history.push('/tournament');
                return;
            }
            let temp = list;
            let totalPlayer = 0;
            let tempFormula = res.data.event_calculation.find(calc => calc.formula.handicap)
            setTournamentDetails({ formula: tempFormula });
            setTournamentStatus(res.data.game_status);
            temp.headers = [
                { field: 'hole_no', headerName: translate(lang, 'holeNo'), flex: 0, minWidth: 50, restricted: [], },
                {
                    field: 'distance', headerName: translate(lang, 'distance'), flex: 0.5, minWidth: 200, restricted: [], renderCell: (params) => {
                        let distances = params.row.distance;
                        return distances.length < 1 ? <></> : distances.map(dist => (
                            <span className="p-2" style={{ backgroundColor: dist.color }}><span className="box-color-text">{dist.distance}</span></span>
                        ))
                    },
                    headerAlign: 'center',
                    align: 'center',
                },
                { field: 'par', headerName: translate(lang, 'par'), flex: 0, minWidth: 50, restricted: [], headerAlign: 'center', align: 'center', },
                {
                    field: 'difficulty', headerName: translate(lang, 'difficultyIndex'), flex: 0.5, minWidth: 50, restricted: [],
                    headerAlign: 'center', align: 'center',
                    renderCell: (params) => {
                        return (params.id === 'total' ? <><span className="h5 font-900 ms-auto my-0">{translate(lang, "totalScore")}</span></> :
                            params.id === 'total_handicap' ? <><span className="h5 font-900 ms-auto my-0">{translate(lang, "totalHandicap")}</span></> :
                                params.difficulty)
                    }
                },
            ];
            temp.items = [];
            temp.players = [];
            if (res.data.tournament_map && res.data.tournament_map.tournament_courses) {
                res.data.tournament_map.tournament_courses.map((course, index) => {
                    temp.items.push({
                        id: course.id,
                        hole_no: course.hole_no,
                        distance: course.distance,
                        par: course.par,
                        difficulty: course.difficulty,
                    })
                    return true;
                })
                temp.items.push({
                    id: 'total',
                    hole_no: "",
                    distance: "",
                    par: "",
                    difficulty: [],
                })
                temp.items.push({
                    id: 'total_handicap',
                    hole_no: "",
                    distance: "",
                    par: "",
                    difficulty: [],
                })
            }
            if (res.data.tournament_players) {
                res.data.tournament_players.map((player, index) => {
                    totalPlayer++;
                    temp.players.push({ id: player.id, target: `p${index}_${player.name}`, handicap: `h${index}_${player.name}` })
                    temp.headers.push({
                        field: `p${index}_${player.name}`,
                        editable: ['handicap_start'].includes(res.data.game_status) ? true : false,
                        headerName: player.name,
                        flex: 1,
                        minWidth: 100,
                        headerClassName: 'tournament-player-header',
                        cellClassName: 'tournament-player-cell',
                        headerAlign: 'center',
                        align: 'center',
                        renderCell: (params) => {
                            // let score = params.row.par - params.row[`p${index}_${player.name}`];
                            // score = score > -2 ? score > -1 ? 2 : 1 : 0;
                            let score2 = 0;
                            let total = 0;
                            let totalHandicap = 0;
                            if (params.row[`p${index}_${player.name}`] > 0) {
                                score2 = checkingValueWithFormula(tempFormula.formula.calculation[0], { par: params.row.par, stroke: params.row[`p${index}_${player.name}`] });
                                let result = tempFormula.formula.results.find(r => r.result === score2);
                                if (!result) {
                                    let sorted = tempFormula.formula.results.sort((a, b) => a.result > b.result ? a : -1);
                                    let highest = sorted[sorted.length - 1];
                                    let lowest = sorted[0];
                                    score2 = score2 > highest.result ? highest.value : lowest.value;
                                } else {
                                    score2 = result.value;
                                }
                                total = temp.items.filter(item => typeof item.id !== "string").reduce((acc, item) => acc += item[`p${index}_${player.name}`], 0)
                            } else {

                            }
                            totalHandicap = temp.items.filter(item => typeof item.id !== "string").reduce((acc, item) => {
                                console.log(item, acc, tempFormula);
                                let s = checkingValueWithFormula(tempFormula.formula.calculation[0], { par: item.par, stroke: item[`p${index}_${player.name}`] });
                                let result = tempFormula.formula.results.find(r => r.result === s);
                                if (item[`p${index}_${player.name}`] < 1) {
                                    acc += 0;
                                } else if (!result) {
                                    let sorted = tempFormula.formula.results.sort((a, b) => a.result > b.result ? a : -1);
                                    let highest = sorted[sorted.length - 1];
                                    let lowest = sorted[0];
                                    s = s > highest.result ? highest.value : lowest.value;
                                    acc += s
                                } else {
                                    s = result.value;
                                    acc += s
                                }
                                // let calculation = item.par - item[`p${index}_${player.name}`];
                                return acc;
                            }, 0)
                            return typeof params.id === "string" ? params.id === 'total' ? `${Math.abs(total)}` :
                                `${Math.abs(totalHandicap)}` :
                                `${params.row[`p${index}_${player.name}`]} (${score2})`;
                        },
                    },
                        // {
                        //     field: `h${index}_${player.name}`,
                        //     editable: false,
                        //     headerName: translate(lang, 'handicap'),
                        //     flex: 1,
                        //     minWidth: 100,
                        //     headerClassName: 'tournament-handicap-header',
                        //     cellClassName: 'tournament-handicap-cell',
                        //     headerAlign: 'center',
                        //     align: 'center',
                        // }
                    )
                    return true;
                })
                for (let x = 0; x < totalPlayer; x++) {
                    let player_data = res.data.tournament_players.find(p => p.id === temp.players[x].id);
                    let totalHandicap = 0;
                    let totalScore = 0;
                    temp.items = temp.items.map((item) => {
                        if (player_data.handicap_plays && player_data.handicap_plays.length) {
                            let courseExist = player_data.handicap_plays.find(m => m.course_id === item.id);
                            if (courseExist) {
                                item[temp.players[x].target] = courseExist ? courseExist.stroke : 0;
                                item[temp.players[x].handicap] = courseExist ? courseExist.handicap : 0;
                                totalScore += courseExist.stroke;
                                totalHandicap += courseExist.handicap;
                            }
                        } else {
                            item[temp.players[x].target] = 0;
                            item[temp.players[x].handicap] = 0;
                        }
                        if (item.id === 'total') {
                            item[temp.players[x].target] = totalScore;
                            item[temp.players[x].handicap] = totalHandicap;
                        }
                        return item;
                        // console.log(player_data.handicap_plays.find(m => m.course_id === item.course_id).score);
                        // item[temp.players[x].target] = player_data.handicap_plays.length ? player_data.handicap_plays.find(m => m.course_id === item.course_id).score : 0;
                    })
                }
            }
            setList({
                ...temp,
            })
        } else {
        }
    }

    function updateMarks(updated) {
        let temp = list;
        let total = temp.items.find((item) => item.id === 'total');
        let totalIndex = temp.items.findIndex((item) => item.id === 'total');
        let index = temp.items.findIndex((item) => item.id === updated.id);
        temp.players.map((player) => {
            total[player.target] += (parseInt(updated[player.target]) - temp.items[index][player.target]);
            total[player.handicap] += (parseInt(updated[player.handicap]) - temp.items[index][player.handicap]);
            updated[player.target] = parseInt(updated[player.target])
            updated[player.handicap] = parseInt(updated[player.handicap])
            return true;
        })
        temp.items[index] = updated;
        temp.items[totalIndex] = total;
        // temp.items[totalIndex] = ;
        // console.log(totalIndex, updated);

        setList({
            ...temp,
            items: temp.items,
        })
    }

    function updateMarksError(e) {
        console.log(e);
    }

    async function updateTournamentMarkings() {
        showLoader(lang, true);
        let data = [];
        list.players.map((player) => {
            let markings = list.items.filter(item => typeof item.id !== 'string').map(item => ({
                course_id: item.id,
                stroke: item[player.target],
                hole_no: item.hole_no,
                par: item.par,
                difficulty: item.difficulty,
                handicap: item[player.handicap],
            }))
            data.push({
                player_id: player.id,
                markings: markings,
                isHandicap: true
            })
            return true;
        })
        const res = await updateTournamentMarks(data, tId)
        showLoader(lang, false);
        if (res.status === 'success') {
            promptMessage(lang, 'success', res.message)
        } else {
            promptMessage(lang, 'error', res.message ?? "error");
        }
    }

    function endHandicap() {
        Swal.fire({
            icon: 'warning',
            title: translate(props.lang, "endHandicapTitle"),
            html: translate(props.lang, "endHandicapMessage"),
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
        }).then(async (result) => {
            showLoader(lang, true);
            if (result.isConfirmed) {
                let res = await updateTournamentStatus(tId, 'ended')
                showLoader(lang, false);
                if (res.status === 'success') {
                    promptMessage(lang, 'success', res.message)
                    getList();
                } else {
                    promptMessage(lang, 'error', res.message ?? "error")
                    getList();
                }
            }
        })
    }

    return (
        <>
            <div className="card shadow">
                <div className="card-header">
                    <div className="d-flex flex-column flex-md-row gap-3 justify-content-center">
                        <h3 className="m-0">{translate(lang, 'tournament')}: {tName} </h3>
                        <span className="badge badge-info my-auto cursor-pointer" title={`Status: ` + translate(lang, tournamentStatus)}>{translate(lang, tournamentStatus)}</span>
                    </div>
                    <div>
                        {!['handicap_start'].includes(tournamentStatus) || list.players.length < 1 ? <></> : <>
                            <button className="btn btn-primary btn-sm me-3" onClick={() => updateTournamentMarkings()}>{translate(lang, 'update')}</button>
                            <button className="btn btn-success btn-sm text-black" onClick={() => endHandicap()}>{translate(lang, 'endHandicap')}</button>
                        </>}
                    </div>
                </div>
                <div className="card-body">
                    {/* <MuiTable list={{ ...list }} lang={props.lang}></MuiTable> */}
                    <div className="d-flex flex-column col-12 gap-3" style={{ minHeight: '10rem' }}>
                        <div className="d-flex flex-column flex-lg-row justify-content-between">
                            <Link to="/tournament" className="btn btn-sm btn-dark">{translate(lang, 'back')}</Link>
                            <span className="mt-auto">{translate(lang, "note")}: {translate(lang, "clickToEditTable")}</span>                        
                        </div>
                        <div className="col-12" style={{ height: `${list.items.length > 0 ? 'auto' : '15rem'}` }}>
                            {/* Table */}
                            {
                                <DataGrid
                                    getRowId={(row, index) => row.id}
                                    rows={[...list.items]}
                                    columns={[...list.headers]}
                                    processRowUpdate={(updatedRow, originalRow) => {
                                        updateMarks(updatedRow)
                                        return updatedRow
                                    }}
                                    slots={{
                                        footer: (props) => gridCustomFooter,
                                    }}
                                    slotProps={{
                                        footer: list.items,
                                    }}
                                    onProcessRowUpdateError={updateMarksError}
                                    isCellEditable={(params) => !['total_handicap', 'total'].includes(params.id) ? true : false}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export const gridCustomFooter = (props, tournament) => {
    // console.log(props);
    return (
        <>
            <span className="border-0"></span>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(TournamentHandicapPlay);