// const url = 'http://localhost:3700';
// const url = "https://api.test.bgzone.asia:3800";
const url = 'https://api.awana-golf.ithouz.com:3800';
const version = '1.0';

const api = {
    login: url + '/users/signin',
    users: url + '/users',
    userProfile: url + '/user/profile/image',
    companyLogo: url + '/company/logo',
}

export  { api, url, version };