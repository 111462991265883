import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {  useHistory, useParams } from "react-router-dom";
import { translate } from "../../../utils/translate";
import { findDuplicatesInArray, promptMessage, showLoader } from "../../../utils/functions";
import { createMap, editMap, getMapDetails } from "../../../services/ApiService";
import Swal from "sweetalert2";


const MapForm = (props) => {
    const lang = props.lang;
    const { type, id } = useParams();
    const history = useHistory();
    const [data, setData] = useState({
        name: "",
        noOfHoles: 18,
        ratings: [{
            color: '#ff0000',
            slopeRating: 0,
            courseRating: 0,
            description: '',
        }, {
            color: '#00ff00',
            slopeRating: 0,
            courseRating: 0,
            description: '',
        }, {
            color: '#0000ff',
            slopeRating: 0,
            courseRating: 0,
            description: '',
        }],
    });
    const [error, setError] = useState({
        name: "",
        noOfHoles: "",
        ratings: "",
    });

    useEffect(() => {
        if (type === 'create') {
            setData({
                name: "",
                noOfHoles: 18,
                ratings: [{
                    color: '#009dff',
                    slopeRating: 0,
                    courseRating: 0,
                    description: '',
                }, {
                    color: '#ffffff',
                    slopeRating: 0,
                    courseRating: 0,
                    description: '',
                }, {
                    color: '#cc0000',
                    slopeRating: 0,
                    courseRating: 0,
                    description: '',
                }],
            })
        } else if (type === 'view') {
            if (!id) {
                history.push('/map');
                return;
            }
            getDetails(id);
        } else if (type === 'edit') {
            if (!id) {
                history.push('/map');
                return;
            }
            getDetails(id);
        }
    }, [])

    const getDetails = async (id) => {
        const res = await getMapDetails(id);
        if (res.status === 'success') {
            let temp = data;
            temp.name = res.data.name;
            temp.noOfHoles = res.data.numberOfHoles;
            temp.ratings = [];
            if (res.data.ratings) {
                temp.ratings = res.data.ratings.map((rate) => ({
                    color: rate.color,
                    slopeRating: rate.slopeRating,
                    courseRating: rate.courseRating,
                    description: rate.description,
                }))
            }
            setData({ ...temp });
        } else {
            history.push('/map');
            return;
        }
    }

    // const handleOptionSelected = (e, name) => {
    //     const { value } = e;
    //     const list = { ...data };
    //     list[name] = value;
    //     setData(list);
    // }

    const submitForm = async () => {
        await showLoader(lang, true);
        let check = true;
        let error = { name: "", noOfHoles: "", ratings: "" }
        if (!data.name) {
            check = false;
            error.name = translate(lang, "errorRequired");
        }
        if (!data.noOfHoles) {
            check = false;
            error.noOfHoles = translate(lang, "errorRequired");
        }
        // check for same color 
        let ratings = data.ratings;
        let sameColor = findDuplicatesInArray(ratings, 'color');
        if (sameColor.find(item => item.value > 1)) {            
            check = false;
            error.ratings = translate(lang, "noSameColorAllowed");
            Swal.fire({
                title: translate(lang, 'error'),
                html: `<p class="text-uppercase">${translate(lang, "noSameColorAllowed")}</p>`,
                icon: 'error',
            })
        }
        setError({ ...error });
        if (check) {
            data.noOfHoles = parseInt(data.noOfHoles);
            let res;
            if (type === 'create') {
                res = await createMap(data);
            } else if (type === 'edit') {
                res = await editMap(id, data);
            }
            await showLoader(lang, true);
            if (res.status === 'success') {
                Swal.fire({
                    title: translate(lang, 'success'),
                    html: `<p class="text-uppercase">${res.message}</p>`,
                    icon: 'success',
                    timer: 1500,
                }).then(() => history.push('/map'))
                resetForm();
            } else {
                Swal.fire({
                    title: translate(lang, 'error'),
                    html: `<p class="text-uppercase">${res.message}</p>`,
                    icon: 'error',
                })
            }
        } else {
            promptMessage(lang, 'error', translate(lang, "formErrorMessage"));
        }
    }

    const resetForm = async () => {
        setData({
            name: "",
        })
    }

    const handleRatingForm = (action, index = -1) => {
        let tempArr = data.ratings;
        if (action === 'add') {
            tempArr.push({
                color: '#f0f0f0',
                slopeRating: 0,
                courseRating: 0,
                description: '',
            })
        } else if (action === 'remove') {
            if (index > -1) {
                tempArr.splice(index, 1);
            }
        }
        setData({ ...data, ratings: tempArr });
    }

    const ratingFormOnChange = (e, index) => {
        const { value, name } = e.target;
        let tempArr = data.ratings;
        tempArr[index][name] = value;
        setData({ ...data, ratings: tempArr })
    }

    return (
        <>
            <div className="card col-12 col-md-8 col-xl-6">
                <div className="card-header">
                    <h3 className="m-0">
                        {translate(lang, (type === "view" ? "mapDetails" : type === "edit" ? "editMap" : "addMap"))}
                    </h3>
                </div>
                <div className="card-body col-12 d-flex flex-column">
                    <div className="d-flex flex-column flex-md-row gap-2">
                        <div className="form-group py-2 col-12 col-md-8">
                            <label className="form-label">{translate(lang, 'name')}</label>
                            <span className="ms-2 text-danger">*</span>
                            <input type="text" value={data.name} onChange={(e) => { setData({ ...data, name: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                            {error.name === "" ? <></> : <p className="text-danger">{error.name}</p>}
                        </div>
                        <div className="form-group py-2 col-12 col-md-4">
                            <label className="form-label">{translate(lang, 'numberOfHoles')}</label>
                            <span className="ms-2 text-danger">*</span>
                            <input type="number" value={data.noOfHoles} onChange={(e) => {if (type === 'create') setData({ ...data, noOfHoles: e.target.value })}} className="form-control" disabled={type === 'create' ? false : true} readOnly={type === 'create' ? false : true}  placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                            {error.noOfHoles === "" ? <></> : <p className="text-danger">{error.noOfHoles}</p>}
                        </div>
                    </div>
                    <div className="card shadow">
                        <div className="card-body">
                            <h5>{translate(props.lang, 'ratings')}</h5>
                            <span className="ms-2 text-danger">*</span>
                            {error.ratings === "" ? <></> : <p className="text-danger">{error.ratings}</p>}
                            {data.ratings && data.ratings.map((rating, index) => {
                                return (
                                    <div className="card shadow" key={index}>
                                        <div className="card-body d-flex flex-column gap-2">
                                            <div className="d-flex flex-column flex-md-row gap-3">
                                                <div className="form-group py-2 col-12 col-md-3">
                                                    <label className="form-label">{translate(lang, 'color')}</label>
                                                    <input type="color" name="color" value={rating.color} onChange={(e) => ratingFormOnChange(e, index)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                                </div>
                                                <div className="form-group py-2 col-12 col-md">
                                                    <label className="form-label">{translate(lang, 'description')}</label>
                                                    <input type="text" name="description" value={rating.description} onChange={(e) => ratingFormOnChange(e, index)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column flex-md-row gap-3">
                                                <div className="form-group py-2 col-12 col-md">
                                                    <label className="form-label">{translate(lang, 'slopeRating')}</label>
                                                    <input type="number" name="slopeRating" onWheel={(e) => e.target.blur()} value={rating.slopeRating} onChange={(e) => ratingFormOnChange(e, index)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                                </div>
                                                <div className="form-group py-2 col-12 col-md">
                                                    <label className="form-label">{translate(lang, 'courseRating')}</label>
                                                    <input type="number" name="courseRating" onWheel={(e) => e.target.blur()} value={rating.courseRating} onChange={(e) => ratingFormOnChange(e, index)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                                </div>
                                            </div>
                                            {data.ratings.length > 1 ?
                                                <button className="btn btn-danger" onClick={() => handleRatingForm('remove', index)}>{translate(props.lang, 'delete')}</button>
                                                : <></>
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                            <div>
                                <button className="btn btn-primary" onClick={() => handleRatingForm('add')}>{translate(props.lang, 'addRating')}</button>
                            </div>
                        </div>
                    </div>
                    {/* <div className="card shadow">
                        <div className="card-body">
                            <h5>{translate(props.lang, 'course')}</h5>
                            <div className="card">
                                <div className="form-group py-2">
                                    <label className="form-label">{translate(lang, 'holeNo')}</label>
                                    <input type="text" value={data.holeNo} onChange={(e) => { setData({ ...data, name: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                </div>
                                <div className="form-group py-2">
                                    <label className="form-label">{translate(lang, 'par')}</label>
                                    <input type="text" value={data.par} onChange={(e) => { setData({ ...data, name: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                </div>
                                <div className="form-group py-2">
                                    <label className="form-label">{translate(lang, 'diffculty')}</label>
                                    <input type="text" value={data.difficulty} onChange={(e) => { setData({ ...data, name: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                </div>
                            </div>
                            <div>
                                <button className="btn btn-primary">{translate(props.lang, 'addCourse')}</button>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="card-footer">
                    <div className="d-flex flex-row">
                        <button type="button" hidden={type === 'view' ? true : false} className="btn btn-primary btn-sm" onClick={() => submitForm()}>{translate(lang, `${type === 'edit' ? 'save' : type}`)}</button>
                        <button type="button" className="btn btn-dark mx-2 btn-sm" onClick={() => history.push('/map')}>{translate(lang, "back")}</button>
                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(MapForm);