import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { getTournamentById, updateTournamentMarks, updateTournamentStatus } from "../../../services/ApiService";
import { checkingValueWithFormula, promptMessage, showLoader } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import { DataGrid } from '@mui/x-data-grid';

const TournamentMarking = (props) => {
    const lang = props.lang;
    const { tName, tId } = useParams();
    const [tournamentDetails, setTournamentDetails] = useState({
        game_status: "",
        handicap_play_off: "",
        isEditable: true,
        formula: '',
    });
    const [list, setList] = useState({
        items: [],
        players: [],
        headers: [
            { field: 'hole_no', headerName: translate(lang, 'holeNo'), flex: 0, minWidth: 50, restricted: [], },
            {
                field: 'distance', headerName: translate(lang, 'distance'), flex: 0.5, minWidth: 200, restricted: [], renderCell: (params) => {
                    let distances = params.row.distance;
                    return distances.map(dist => (
                        <span className="p-2" style={{ backgroundColor: dist.color }}><span className="box-color-text">{dist.distance}</span></span>
                    ))
                }
            },
            { field: 'par', headerName: translate(lang, 'par'), flex: 0, minWidth: 100, restricted: [], },
            { field: 'difficulty', headerName: translate(lang, 'index'), flex: 0.5, minWidth: 100, restricted: [], },
            // { field: 'actions', type:'actions', headerName: translate(lang, 'action'), flex: 2, minWidth: 300, restricted: ['agent', 'player'], getActions: (params) => [
            //     <Link to={`/tournament/${params.row.title}/${params.id}/player`} title={translate(lang, "players")} className=" btn btn-sm btn-info cursor-pointer"><FontAwesomeIcon icon={faPeopleGroup}></FontAwesomeIcon></Link>,
            //     <button onClick={() => startHandicap(params.id)} title={translate(lang, "startHandicap")} className="btn btn-sm btn-secondary cursor-pointer"><FontAwesomeIcon icon={faHand}></FontAwesomeIcon></button>,
            //     <button onClick={() => startTournament(params.id)} title={translate(lang, "startTournament")} className="btn btn-sm btn-primary cursor-pointer"><FontAwesomeIcon icon={faFlagCheckered}></FontAwesomeIcon></button>,
            //     <Link to={`/tournament/edit/${params.id}`} title={translate(lang, "save")} className=" btn btn-sm btn-warning cursor-pointer"><FontAwesomeIcon icon={faMarker}></FontAwesomeIcon></Link>,
            //     <button onClick={() => removeItem(params.id)} title={translate(lang, "delete")} className="btn btn-sm btn-danger cursor-pointer"><FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon></button>,
            // ]},
        ],
    });
    // const [exportData, setExportData] = useState({
    //     name: "tournament_marks_report",
    //     excelData: [],
    //     hasCustom: false,
    //     customRows: [{
    //         customData: [],
    //         origin: -1, // last row or specified location like 'A1'
    //     }],
    // });

    useEffect(() => {
        getList()
    }, [])

    async function getList() {
        let res = await getTournamentById(tId);
        if (res?.status === 'success') {
            let temp = list;
            let totalPlayer = 0;
            let tempDetail = tournamentDetails;
            // console.log(res.data.handicap_play_off ? (['handicap_completed', 'tournament_start'].includes(res.data.game_status) ? true : false) : (['tournament_completed'].includes(res.data.game_status) ? false : true ))
            let tempFormula = res.data.event_calculation.find(calc => !calc.formula.handicap)
            tempDetail = {
                game_status: res.data.game_status,
                handicap_play_off: res.data.handicap_play_off,
                isEditable: res.data.handicap_play_off ? (['handicap_completed', 'tournament_start'].includes(res.data.game_status) ? true : false) : (['tournament_start'].includes(res.data.game_status) ? true : false),
                formula: tempFormula.formula,
            }
            setTournamentDetails({ ...tempDetail });
            temp.headers = [
                { field: 'hole_no', headerName: translate(lang, 'holeNo'), flex: 0, minWidth: 50, restricted: [], },
                {
                    field: 'distance', headerName: translate(lang, 'distance'), flex: 0.5, minWidth: 200, restricted: [], renderCell: (params) => {
                        let distances = params.row.distance;
                        return distances.length < 1 ? <></> : distances.map(dist => (
                            <span className="p-2" style={{ backgroundColor: dist.color }}><span className="box-color-text">{dist.distance}</span></span>
                        ))
                    },
                    headerAlign: 'center',
                    align: 'center',
                },
                { field: 'par', headerName: translate(lang, 'par'), flex: 0, minWidth: 50, restricted: [], headerAlign: 'center', align: 'center', },
                {
                    field: 'difficulty', headerName: translate(lang, 'difficultyIndex'), flex: 0.5, minWidth: 150, restricted: [], headerAlign: 'center', align: 'center', renderCell: (params) => {
                        return params.id === 'totalStroke' ? <><span className="h5 font-900 m-0">{translate(lang, "total")}</span></>
                            : params.id === 'totalScore' ? <><span className="h5 font-900 m-0">{translate(lang, "totalScore")}</span></>
                                : params.difficulty
                    },
                },
            ];
            temp.items = [];
            temp.players = [];
            if (res.data.tournament_map && res.data.tournament_map.tournament_courses) {
                res.data.tournament_map.tournament_courses.map((course, index) => {
                    temp.items.push({
                        id: course.id,
                        hole_no: course.hole_no,
                        distance: course.distance,
                        par: course.par,
                        difficulty: course.difficulty,
                    })
                    return true;
                })
                temp.items.push({
                    id: 'totalStroke',
                    hole_no: "",
                    distance: "",
                    par: "",
                    difficulty: [],
                })
                temp.items.push({
                    id: 'totalScore',
                    hole_no: "",
                    distance: "",
                    par: "",
                    difficulty: [],
                })
            }
            if (res.data.tournament_players) {
                res.data.tournament_players.map((player, index) => {
                    totalPlayer++;
                    temp.players.push({ id: player.id, target: `p${index}_${player.name}`, handicap: `h${index}_${player.name}`, score: `s${index}_${player.name}` })
                    temp.headers.push({
                        field: `p${index}_${player.name}`,
                        editable: tempDetail.isEditable,
                        headerName: player.name,
                        flex: 1,
                        minWidth: 100,
                        headerClassName: 'tournament-player-header',
                        cellClassName: 'tournament-player-cell',
                        headerAlign: 'center',
                        align: 'center',
                        renderCell: (params) => {
                            let score = 0;
                            let total = 10;
                            if (params.row[`p${index}_${player.name}`] > 0) {
                                score = checkingValueWithFormula(tempFormula.formula.calculation[0], { par: params.row.par, stroke: params.row[`p${index}_${player.name}`], handicap: params.row[`h${index}_${player.name}`] });
                                let result = tempFormula.formula.results.find(r => r.result === score);
                                if (!result) {
                                    let sorted = tempFormula.formula.results.sort((a, b) => a.result > b.result ? a : -1);
                                    let highest = sorted[sorted.length - 1];
                                    let lowest = sorted[0];
                                    score = score > highest.result ? highest.value : lowest.value;
                                } else {
                                    score = result.value;
                                }
                            } else {
                            }
                            total = temp.items.filter(item => typeof item.id !== "string").reduce((acc, item) => acc += item[`p${index}_${player.name}`], 0)
                            // totalHandicap = temp.items.filter(item => typeof item.id !== "string").reduce((acc, item) => {
                            //     // console.log(item, acc);
                            //     let s = checkingValueWithFormula(tempFormula.formula.calculation[0], { par: item.par, stroke: item[`p${index}_${player.name}`], handicap: item[`h${index}_${player.name}`] });
                            //     let result = tempFormula.formula.results.find(r => r.result === s);
                            //     if (item[`p${index}_${player.name}`] < 1) {
                            //         acc += 0;
                            //     } else if (!result) {
                            //         let sorted = tempFormula.formula.results.sort((a, b) => a.result > b.result ? a : -1);
                            //         let highest = sorted[sorted.length - 1];
                            //         let lowest = sorted[0];
                            //         s = s > highest.result ? highest.value : lowest.value;
                            //         acc += s
                            //     } else {
                            //         s = result.value;
                            //         acc += s
                            //     }
                            //     // let calculation = item.par - item[`p${index}_${player.name}`];
                            //     return acc;
                            // }, 0)
                            return typeof params.id === "string" ? params.id === 'totalStroke' ? `${Math.abs(total)}` :
                                `${Math.abs(params.row[`s${index}_${player.name}`])}` :
                                `${params.row[`p${index}_${player.name}`]} (${score})`;
                        },
                    }, {
                        field: `h${index}_${player.name}`,
                        editable: false,
                        headerName: translate(lang, 'handicap'),
                        flex: 1,
                        minWidth: 100,
                        headerClassName: 'tournament-handicap-header',
                        cellClassName: 'tournament-handicap-cell',
                        headerAlign: 'center',
                        align: 'center',
                        renderCell: (params) => {
                            let totalHandicap = 0;
                            totalHandicap = temp.items.filter(item => typeof item.id !== "string").reduce((acc, item) => {
                                acc += item[`h${index}_${player.name}`];
                                // let calculation = item.par - item[`p${index}_${player.name}`];
                                return acc;
                            }, 0)
                            return typeof params.id === "string" ? params.id === 'totalStroke' ? `${Math.abs(totalHandicap)}` :
                                `${''}` :
                                `${params.row[`h${index}_${player.name}`]}`;
                        },
                    })
                    return true;
                })
                for (let x = 0; x < totalPlayer; x++) {
                    let player_data = res.data.tournament_players.find(p => p.id === temp.players[x].id);
                    let totalHandicap = 0;
                    let totalStroke = 0;
                    let totalScore = 0;
                    temp.items = temp.items.map((item) => {
                        if (player_data.markings.length) {
                            let courseExist = player_data.markings.find(m => m.course_id === item.id);
                            if (courseExist) {
                                item[temp.players[x].target] = courseExist ? courseExist.stroke : 0;
                                item[temp.players[x].handicap] = courseExist ? courseExist.handicap : 0;
                                totalStroke += courseExist.stroke;
                                totalHandicap += courseExist.handicap;
                                let score = checkingValueWithFormula(tempFormula.formula.calculation[0], { par: item.par, stroke: item[temp.players[x].target], handicap: item[temp.players[x].handicap] });
                                let result = tempFormula.formula.results.find(r => r.result === score);
                                if (!result) {
                                    let sorted = tempFormula.formula.results.sort((a, b) => a.result > b.result ? a : -1);
                                    let highest = sorted[sorted.length - 1];
                                    let lowest = sorted[0];
                                    score = score > highest.result ? highest.value : lowest.value;
                                } else {
                                    score = result.value;
                                }
                                totalScore += item[temp.players[x].target] > 0 ? score : 0;
                            }
                        } else {
                            item[temp.players[x].target] = 0;
                            item[temp.players[x].handicap] = 0;
                        }
                        if (item.id === 'totalStroke') {
                            item[temp.players[x].target] = totalStroke;
                            item[temp.players[x].handicap] = totalHandicap;
                        }
                        if (item.id === 'totalScore') {
                            item[temp.players[x].score] = totalScore;
                        }
                        return item;
                        // console.log(player_data.markings.find(m => m.course_id === item.course_id).score);
                        // item[temp.players[x].target] = player_data.markings.length ? player_data.markings.find(m => m.course_id === item.course_id).score : 0;
                    })
                }
            }
            setList({
                ...temp,
            })
        } else {
        }
    }

    function updateMarks(updated) {
        let temp = list;
        let total = temp.items.find((item) => item.id === 'totalStroke');
        let totalIndex = temp.items.findIndex((item) => item.id === 'totalStroke');
        let totalScore = temp.items.find((item) => item.id === 'totalScore');
        let totalScoreIndex = temp.items.findIndex((item) => item.id === 'totalScore');
        let index = temp.items.findIndex((item) => item.id === updated.id);
        for (let x = 0; x < temp.players.length; x++) {
            let player = temp.players[x];
            // temp.players.map((player) => {
            // count mark
            let oldScore = checkingValueWithFormula(tournamentDetails.formula.calculation[0], { par: temp.items[index].par, stroke: temp.items[index][player.target], handicap: temp.items[index][player.handicap] });
            let score = checkingValueWithFormula(tournamentDetails.formula.calculation[0], { par: updated.par, stroke: updated[player.target], handicap: updated[player.handicap] });
            let oldResult = tournamentDetails.formula.results.find(r => r.result === oldScore);
            let result = tournamentDetails.formula.results.find(r => r.result === score);
            let sorted = tournamentDetails.formula.results.sort((a, b) => a.result > b.result ? a : -1);
            let highest = sorted[sorted.length - 1];
            let lowest = sorted[0];

            if (updated[player.target] > 0) {
                if (!result) {
                    score = score > highest.result ? highest.value : lowest.value;
                } else {
                    score = result.value;
                }
                if (temp.items[index][player.target] > 0) {
                    if (!oldResult) {
                        oldScore = oldScore > highest.result ? highest.value : lowest.value;
                    } else {
                        oldScore = oldResult.value;
                    }
                } else {
                    oldScore = 0;
                }
            } else {
                score = 0;
                oldScore = 0;
            }
            // console.log(player.score, oldScore, score, temp.items[index][player.target], updated[player.target])
            totalScore[player.score] += (parseInt(score) - parseInt(oldScore));
            total[player.target] = (parseInt(updated[player.target]) - temp.items[index][player.target]);
            updated[player.target] = parseInt(updated[player.target])
            updated[player.handicap] = parseInt(updated[player.handicap])
        }
        // console.log(temp.items[totalScoreIndex], updated, totalScore[]);
        temp.items[index] = updated;
        temp.items[totalIndex] = total;
        temp.items[totalScoreIndex] = totalScore;

        setList({
            ...temp,
            items: temp.items,
        })
    }

    function updateMarksError(e) {
        console.log(e);
    }

    async function updateTournamentMarkings() {
        showLoader(lang, true);
        let data = [];
        list.players.map((player) => {
            let markings = list.items.map(item => ({
                course_id: item.id,
                stroke: item[player.target],
                hole_no: item.hole_no,
                handicap: item[player.handicap],
            }))
            data.push({
                player_id: player.id,
                markings: markings,
            })
            return true;
        })
        const res = await updateTournamentMarks(data, tId);
        showLoader(lang, false);
        if (res.status === 'success') {
            promptMessage(lang, 'success', res.message)
        } else {
            promptMessage(lang, 'error', res.message ?? "error")
        }
    }

    function endTournament() {
        Swal.fire({
            icon: 'warning',
            title: translate(props.lang, "endTournamentTitle"),
            html: translate(props.lang, "endTournamentMessage"),
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
        }).then(async (result) => {
            showLoader(lang, true);
            if (result.isConfirmed) {
                let res = await updateTournamentStatus(tId, 'ended')
                showLoader(lang, false);
                if (res.status === 'success') {
                    promptMessage(lang, 'success', res.message)
                    getList();
                } else {
                    promptMessage(lang, 'error', res.message ?? "error")
                    getList();
                }
            }
        })
    }

    return (
        <>
            <div className="card shadow">
                <div className="card-header">
                    <div className="d-flex flex-column flex-md-row gap-3 justify-content-center">
                        <h3 className="m-0">{translate(lang, 'tournament')}: {tName}</h3>
                        <span className="badge badge-info my-auto cursor-pointer" title={`Status: ` + translate(lang, tournamentDetails.game_status)}>{translate(lang, tournamentDetails.game_status)}</span>
                    </div>
                    <div>
                        {['tournament_cancel', 'tournament_completed'].includes(tournamentDetails.game_status) ? <>

                        </> : (!tournamentDetails.isEditable ? <>
                            <p className="m-0">{translate(lang, 'waitingHandicapEnd')}</p>
                        </> : <>
                            <button className="btn btn-primary btn-sm me-3" onClick={() => updateTournamentMarkings()}>{translate(lang, 'update')}</button>
                            <button className="btn btn-success btn-sm text-black" onClick={() => endTournament()}>{translate(lang, 'endTournament')}</button>
                        </>)
                        }
                    </div>
                </div>
                <div className="card-body">
                    {/* <MuiTable list={{ ...list }} lang={props.lang}></MuiTable> */}
                    <div className="d-flex flex-column col-12 gap-3" style={{ minHeight: '10rem' }}>
                        <div className="d-flex flex-column flex-lg-row justify-content-between">
                            <Link to="/tournament" className="btn btn-sm btn-dark">{translate(lang, 'back')}</Link>
                            <span className="mt-auto">{translate(lang, "note")}: {translate(lang, "clickToEditTable")}</span>
                        </div>
                        <div className="col-12" style={{ height: `${list.items.length > 0 ? 'auto' : '15rem'}` }}>
                            {/* Table */}
                            {
                                <DataGrid
                                    getRowId={(row, index) => row.id}
                                    rows={[...list.items]}
                                    columns={[...list.headers]}
                                    processRowUpdate={(updatedRow, originalRow) => {
                                        updateMarks(updatedRow)
                                        return updatedRow
                                    }}
                                    onProcessRowUpdateError={updateMarksError}
                                    isCellEditable={(params) => params.id !== 'total' ? true : false}
                                    slots={{
                                        footer: (props) => <></>,
                                    }}
                                    slotProps={{
                                        footer: list.items,
                                    }}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(TournamentMarking);