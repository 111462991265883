import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { createCourse, getMapDetails, removeCourse } from "../../../services/ApiService";
import { promptMessage } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import MuiTable from "../../components/MuiTable";

const CourseList = (props) => {
    const lang = props.lang;
    const tableOptions = { search: false };
    const { mapId } = useParams();
    const history = useHistory();
    const [searchText, setSearchText] = useState('');
    const [ratings, setRatings] = useState([]);
    const [list, setList] = useState({
        items: [],
        headers: [
            { field: 'hole_no', headerName: translate(lang, 'holeNo'), restricted: [], flex: 1, minWidth: 150, },
            { field: 'par', headerName: translate(lang, 'par'), restricted: [], flex: 1, minWidth: 100, },
            { field: 'difficulty', headerName: translate(lang, 'difficultyIndex'), restricted: [], flex: 1, minWidth: 100, },
            {
                field: 'courses.course_distance', headerName: translate(lang, 'distance'), flex: 0, minWidth: 200, restricted: [], renderCell: (params) => {
                    let distances = params.row.course_distances;
                    return distances.map(dist => (
                        <span className="p-2" style={{ backgroundColor: dist.color }}><span className="box-color-text">{dist.distance}</span></span>
                    ))
                },
                headerAlign: 'center',
                align: 'center',
            },
            // {
            //     field: 'actions', type: 'actions', headerName: translate(lang, 'action'), restricted: ['agent', 'player'], flex: 1, minWidth: 100, getActions: (params) => [
            //         <Link to={`/map/course/${mapId}/edit/${params.id}`} title={translate(lang, "save")} className=" btn btn-sm btn-warning cursor-pointer"><FontAwesomeIcon icon={faMarker}></FontAwesomeIcon></Link>,
            //         <button onClick={() => removeItem(params.id)} title={translate(lang, "delete")} className="btn btn-sm btn-danger cursor-pointer"><FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon></button>
            //     ]
            // },
        ],
    });
    const [exportData, setExportData] = useState({
        name: "course_list_report",
        excelData: [],
        hasCustom: false,
        customRows: [{
            customData: [],
            origin: -1, // last row or specified location like 'A1'
        }],
    });

    useEffect(() => {
        getList()
    }, [searchText])

    async function getList() {
        let res = await getMapDetails(mapId);
        if (res?.status === 'success') {
            setList({
                ...list,
                items: [...res.data.courses],
            })
            setRatings([...res.data.ratings])
            setExportData({
                ...exportData,
                excelData: res.data,
            });
        } else {
        }
    }

    function onTextSearched(value) {
        setSearchText(value);
    }

    function onActionTaken(value) {
        if (value === 'remove') {
            removeItem()
        } else if (value === 'edit') {
            history.push('');
        }
    }

    async function addCourse() {
        let data = {
            mapId: parseInt(mapId),
            hole_no: list.items.length + 1,
            par: 1,
            difficulty: list.items.length + 1,
            course_distances: ratings.map(distance => ({ color: distance.color, distance: 100 }))
        };
        const res = await createCourse(data)
        if (res.status === 'success') {
            promptMessage(lang, 'success', res.message)
            getList();
        } else {

        }
    }

    function removeItem() {
        if (list.items.length < 1) {
            promptMessage(lang, 'warning', translate(lang, 'nothingToDelete'))
            return;
        }
        Swal.fire({
            title: translate(props.lang, "deleteLastCourseConfirmation"),
            text: translate(props.lang, "deleteLastCourseMessage"),
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                let id = list.items[list.items.length - 1].id;
                let res = await removeCourse(id)
                if (res.status === 'success') {
                    promptMessage(lang, 'success', res.message)
                    getList();
                } else {
                    promptMessage(lang, 'error', res.message ?? "error")
                    getList();
                }
            }
        })
    }

    return (
        <>
            <div className="card shadow">
                <div className="card-header">
                    <div className="d-flex flex-column flex-md-row gap-2">
                        <Link to={`/map`} className="btn btn-secondary btn-sm">{translate(lang, 'back')}</Link>
                        {/* <Link to={`/map/course/${mapId}/create`} className="btn btn-primary btn-sm">{translate(lang, 'addCourse')}</Link> */}
                        <button className="btn btn-primary btn-sm" type="button" onClick={() => addCourse()}> {translate(lang, 'addCourse')}</button>
                        <button className="btn btn-youtube btn-sm" type="button" onClick={() => removeItem()}> {translate(lang, 'deleteLastCourse')}</button>
                    </div>
                    <div>
                        <Link to={`/map/course/${mapId}/edit`} title={translate(lang, "editCourse")} className=" btn btn-sm btn-info cursor-pointer">{translate(lang, "editCourse")}</Link>
                    </div>
                </div>
                <div className="card-body w-100">
                    <MuiTable list={{ ...list }} role={props.auth.auth.data.role} lang={props.lang} options={tableOptions} exportData={exportData} onTextSearched={(e) => onTextSearched(e)} onActionTaken={(e) => onActionTaken(e)}></MuiTable>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(CourseList);