import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { translate } from "../../../utils/translate";
import Select from 'react-select';
import { ageCalculator, genderOptions, promptMessage, showLoader } from "../../../utils/functions";
import { createTournamentPlayer, editTournamentPlayer, getMemberList, getTournamentPlayerById, getTournamentRatingsById } from "../../../services/ApiService";
import Swal from "sweetalert2";
import { Autocomplete, createFilterOptions } from "@mui/material";


const PlayerForm = (props) => {
    const lang = props.lang;
    const { type, tId, tName, id } = useParams();
    const genderListOptions = genderOptions(props.lang);
    const [ratingListOptions, setRatingListOptions] = useState([]);
    const history = useHistory();
    const [data, setData] = useState({
        name: "",
        age: "",
        gender: "",
        tee_color: "",
        date_of_birth: "",
        email: "",
        phone: "",
        memberId: "",
        isMember: "",
        auto_register: false,
    });
    const [error, setError] = useState({
        name: "",
        age: "",
        tee_color: "",
        date_of_birth: "",
        email: "",
        phone: "",
    });
    const [memberList, setMemberList] = useState([]);

    useEffect(() => {
        getRatings();
        if (!tId || !tName) { history.push('/tournament') }
        if (type === 'create') {
            getMembers();
            setData({
                name: "",
                age: "",
                gender: "",
                tee_color: "",
                date_of_birth: "",
                email: "",
                phone: "",
                memberId: "",
                isMember: "",
                auto_register: false,
            })
        } else if (type === 'view') {
            if (!id) {
                history.push(`/tournament/${tName}/${tId}/player`);
                return;
            }
            getDetails(id);
        } else if (type === 'edit') {
            if (!id) {
                history.push(`/tournament/${tName}/${tId}/player`);
                return;
            }
            getDetails(id);
        }
    }, [])

    const getDetails = async (id) => {
        const res = await getTournamentPlayerById(tId, id)
        if (res.status === 'success') {
            let temp = data;
            Object.assign(temp, res.data);
            temp.memberId = res.data.member_id;
            setData({ ...temp })
        } else {
            history.push(`/tournament/${tName}/${tId}/player`);
            return;
        }
    }

    const getRatings = async () => {
        const res = await getTournamentRatingsById(tId)
        if (res.status === 'success') {
            setRatingListOptions([...res.data.ratings].map(rate => ({ label: rate.color, value: rate.color })));
        } else {

        }
    }

    const getMembers = async () => {
        const res = await getMemberList()
        if (res.status === 'success') {
            setMemberList([...res.data]);
        } else {

        }
    }

    const handleOptionSelected = (e, name) => {
        const { value } = e;
        const list = { ...data };
        list[name] = value;
        setData(list);
    }

    const handleMemberSelected = (e, v) => {
        let temp = data;
        temp = {
            memberId: v.member_id,
            name: v.name,
            email: v.email,
            phone: v.phone,
            gender: v.gender,
            date_of_birth: v.date_of_birth,
            isMember: true,
            age: ageCalculator(v.date_of_birth, { format: 'yyyy-MM-dd' }),
        }
        setData({ ...data, ...temp });
    }

    const submitForm = async (another = false) => {
        showLoader(lang, true);
        let check = true;
        let error = { name: "", age: "", gender: "", date_of_birth: "", email: "", phone: "", }
        if (!data.name) {
            check = false;
            error.name = translate(lang, "errorRequired");
        }
        if (!data.gender) {
            check = false;
            error.gender = translate(lang, "errorRequired");
        }
        if (!data.email) {
            check = false;
            error.email = translate(lang, "errorRequired");
        }
        if (!data.phone) {
            check = false;
            error.phone = translate(lang, "errorRequired");
        }
        if (!data.date_of_birth) {
            check = false;
            error.date_of_birth = translate(lang, "errorRequired");
        }
        if (!data.tee_color) {
            check = false;
            error.tee_color = translate(lang, "errorRequired");
        }
        setError({ ...error });
        if (check) {
            data.age = ageCalculator(data.date_of_birth, { format: 'yyyy-MM-dd' })
            let res = '';
            if (type === 'create') {
                res = await createTournamentPlayer(tId, data);
            } else if (type === 'edit') {
                res = await editTournamentPlayer(tId, id, data);
            }
            showLoader(lang, false);
            if (res.status === 'success') {
                Swal.fire({
                    title: translate(lang, 'success'),
                    html: `<p class="text-uppercase">${res.message}</p>`,
                    icon: 'success',
                    timer: 1500,
                }).then(() => {
                    if (!another) history.push(`/tournament/${tName}/${tId}/player`)
                })
                resetForm();
            } else {
                Swal.fire({
                    title: translate(lang, 'error'),
                    html: `<p class="text-uppercase">${res.message}</p>`,
                    icon: 'error',
                })
            }
        } else {
            promptMessage(lang, 'error', translate(lang, "formErrorMessage"));
        }
    }

    const resetForm = async () => {
        setData({
            name: "",
            age: "",
            date_of_birth: "",
            gender: "",
            email: "",
            phone: "",
            memberId: "",
            isMember: "",
            auto_register: false,
        })
    }

    return (
        <>
            <div className="card shadow col-12 col-md-8">
                <div className="card-header">
                    <h3 className="m-0">
                        {translate(lang, (type === "view" ? "playerDetails" : type === "edit" ? "editPlayer" : "addPlayer"))}
                    </h3>
                </div>
                <div className="card-body col-12 d-flex flex-column flex-md-row flex-md-wrap gap-2">
                    <div className="form-group py-2 col-12">
                        <label className="form-label">{translate(lang, 'memberId')}</label>
                        {type !== 'create' ? <input type="text" value={data.memberId} className="form-control" disabled={true} readOnly placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                            : <Autocomplete
                                id="combo-box-member"
                                options={memberList}
                                inputValue={data.memberId}
                                getOptionLabel={(options) => options.member_id}
                                filterOptions={createFilterOptions({
                                    limit: 100,
                                    stringify: option => option.member_id,
                                })}
                                renderOption={(props, option) => {
                                    return <div {...props} key={option.id}>
                                        <p className="m-0">{option.name} ({option.member_id})</p>
                                    </div>
                                }}
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                        <input type="text" {...params.inputProps} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                    </div>
                                )}
                                onChange={(e, v) => { handleMemberSelected(e, v) }}
                            />}
                    </div>
                    <div className="form-group py-2 col-12 col-md-6">
                        <label className="form-label">{translate(lang, 'fullName')}</label>
                        <span className="ms-2 text-danger">*</span>
                        <input type="text" value={data.name} onChange={(e) => { setData({ ...data, name: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                        {error.name === "" ? <></> : <p className="text-danger">{error.name}</p>}
                    </div>
                    <div className="form-group py-2 col-12 col-md-5">
                        <label className="form-label">{translate(lang, 'gender')}</label>
                        <span className="ms-2 text-danger">*</span>
                        <Select
                            className="form-control"
                            value={genderListOptions.filter(
                                (option) => option.value === data.gender
                            )}
                            isDisabled={type === 'view' ? true : false}
                            onChange={(e) => handleOptionSelected(e, "gender")}
                            options={genderListOptions}
                            placeholder={translate(lang, "placeholderChoose")}
                            styles={{
                                control: (base, state) => ({
                                    ...base,
                                    height: "100%",
                                    border: 0,
                                    borderColor: "transparent",
                                    boxShadow: "none",
                                    "&:hover": {
                                        borderColor: "transparent",
                                        boxShadow: "none",
                                    },
                                    backgroundColor: "transparent",
                                }),
                                valueContainer: (base, state) => ({
                                    ...base,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    borderColor: "transparent",
                                })
                            }}
                        />
                        {error.gender === "" ? <></> : <p className="text-danger">{error.gender}</p>}
                    </div>
                    <div className="form-group py-2 col-12 col-md-6">
                        <label className="form-label">{translate(lang, 'birthDate')}</label>
                        <span className="ms-2 text-danger">*</span>
                        <input type="date" value={data.date_of_birth} onChange={(e) => { ageCalculator(e.target.value, { format: 'dd/MM/yyyy' }); setData({ ...data, date_of_birth: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                        {error.date_of_birth === "" ? <></> : <p className="text-danger">{error.date_of_birth}</p>}
                    </div>
                    {/* <div className="form-group py-2 col-12 col-md-5">
                        <label className="form-label">{translate(lang, 'age')}</label>
                        <input type="text" value={data.age} onChange={(e) => { setData({ ...data, age: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                    </div> */}
                    <div className="form-group py-2 col-12 col-md-6">
                        <label className="form-label">{translate(lang, 'email')}</label>
                        <span className="ms-2 text-danger">*</span>
                        <input type="email" value={data.email} onChange={(e) => { setData({ ...data, email: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                        {error.email === "" ? <></> : <p className="text-danger">{error.email}</p>}
                    </div>
                    <div className="form-group py-2 col-12 col-md-5">
                        <label className="form-label">{translate(lang, 'phone')}</label>
                        <span className="ms-2 text-danger">*</span>
                        <input type="tel" value={data.phone} onChange={(e) => { setData({ ...data, phone: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                        {error.phone === "" ? <></> : <p className="text-danger">{error.phone}</p>}
                    </div>
                    <div className="form-group py-2 col-12 col-md-5">
                        <label className="form-label">{translate(lang, 'teeColor')}</label>
                        <span className="ms-2 text-danger">*</span>
                        <div className="d-flex flex-row gap-2">
                            <Select
                                className="form-control"
                                value={ratingListOptions.filter(
                                    (option) => option.value === data.tee_color
                                )}
                                isDisabled={type === 'view' ? true : false}
                                onChange={(e) => handleOptionSelected(e, "tee_color")}
                                options={ratingListOptions}
                                placeholder={translate(lang, "placeholderChoose")}
                                styles={{
                                    control: (base, state) => ({
                                        ...base,
                                        height: "100%",
                                        border: 0,
                                        borderColor: "transparent",
                                        boxShadow: "none",
                                        "&:hover": {
                                            borderColor: "transparent",
                                            boxShadow: "none",
                                        },
                                        backgroundColor: "transparent",
                                    }),
                                    option: (base, state) => ({
                                        ...base,
                                        ':before': {
                                            backgroundColor: state.value,
                                            borderRadius: "25%",
                                            content: '" "',
                                            display: 'inline-block',
                                            marginRight: 8,
                                            height: 10,
                                            width: 10,
                                            border: "1px solid black"
                                        },
                                        cursor: 'pointer',
                                    }),
                                    valueContainer: (base, state) => ({
                                        ...base,
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                        borderColor: "transparent",
                                    })
                                }}
                            />
                            <div className="m-auto">
                                <span className="box-color" style={{ backgroundColor: data.tee_color }}></span>
                            </div>
                        </div>
                        {error.tee_color === "" ? <></> : <p className="text-danger">{error.tee_color}</p>}
                    </div>
                </div>
                <div className="card-footer">
                    <div className="d-flex flex-row">
                        <button type="button" hidden={type === 'view' ? true : false} className="btn btn-primary btn-sm" onClick={() => submitForm()}>{translate(lang, `${type === 'edit' ? 'save' : type}`)}</button>
                        {type === 'create' ?
                            <button type="button" hidden={type === 'view' ? true : false} className="btn btn-secondary btn-sm mx-2" onClick={() => submitForm(true)}>{translate(lang, `createAnother`)}</button>
                            : <></>
                        }
                        <button type="button" className="btn btn-dark mx-2 btn-sm" onClick={() => history.push(`/tournament/${tName}/${tId}/player`)}>{translate(lang, "back")}</button>
                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(PlayerForm);