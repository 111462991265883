import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { translate } from "../../../utils/translate";
import { gamePlayOptions, promptMessage, unsligify } from "../../../utils/functions";
import { createGamePlay, editGamePlay, getGamePlayById } from "../../../services/ApiService";
import Swal from "sweetalert2";
import { Checkbox } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';


const GamePlayForm = (props) => {
    const lang = props.lang;
    const { type, gameType, id } = useParams();
    const gamePlayTypeOptions = gamePlayOptions(props.lang);
    const history = useHistory();
    const [data, setData] = useState({
        type: "",
        name: "",
        description: "",
        calculation: "",
        values: [],
        markings: [],
        handicap: false,
        editable: false,
    });
    const [error, setError] = useState({
        type: "",
        name: "",
    });
    const [list, setList] = useState({
        items: [],
        headers: [
            {
                field: 'point', headerName: translate(lang, 'point'), flex: 0, minWidth: 50, restricted: [], editable: false, valueGetter: (params) => {
                    return params.row.value;
                }, editable: false,
            },
            {
                field: 'result', headerName: translate(lang, 'result'), flex: 0, minWidth: 100, restricted: [], editable: false, valueGetter: (params) => {
                    return params.row.result;
                }, align: 'center',
            },
            {
                field: 'name', headerName: translate(lang, 'markingName'), flex: 0.5, minWidth: 100, restricted: [], editable: false, valueGetter: (params) => {
                    return unsligify(params.row.name);
                }, renderCell: (params) => {
                    return <span className="text-capitalize">{unsligify(params.row.name)}</span>
                }
            },
            {
                field: 'description', headerName: translate(lang, 'description'), flex: 0.5, minWidth: 100, restricted: [], editable: false, valueGetter: (params) => {
                    return params.row.description;
                }
            },
        ],
    });

    useEffect(() => {
        if (gameType === '' || !gamePlayTypeOptions.find(opt => opt.value === gameType)) {
            history.push('/settings/game');
            return;
        }
        if (type === 'create') {
            setData({
                type: gamePlayTypeOptions.find(opt => opt.value === gameType).label,
                name: "",
                description: "",
                calculation: "",
                values: [],
                markings: [],
                handicap: false,
                editable: false,
            })
        } else if (type === 'view') {
            if (!id) {
                history.push('/settings/game');
                return;
            }
            getDetails(id);
        } else if (type === 'edit') {
            if (!id) {
                history.push('/settings/game');
                return;
            }
            getDetails(id);
        }
    }, [])

    const getDetails = async (id) => {
        const res = await getGamePlayById(id);
        if (res.status === 'success') {
            let tempHeaders = list.headers;
            if (res.data.formula.editable) {
                tempHeaders = tempHeaders.map(item => {
                    if (item.field === 'point') {
                        item.editable = true;
                    }
                    return item
                })
            }
            setList({
                ...list,
                items: [...res.data.formula.results.map((r, index) => { r.id = index; return r })],
                headers: [...tempHeaders]
            })
            let valuesKeys = Object.keys(res.data.formula.values);
            let replaced = res.data.formula.calculation.map((c) => {
                valuesKeys.forEach(k => {
                    c = c.replace(`%${k}%`, res.data.formula.values[k])
                })
                return c;
            })
            setData({
                name: res.data.name,
                type: gamePlayTypeOptions.find(opt => opt.value === res.data.type).label,
                description: res.data.description,
                calculation: replaced,
                values: res.data.formula.values,
                markings: res.data.formula.results,
                editable: res.data.formula.editable,
                handicap: res.data.isHandicap,
            })
        } else {

        }
    }

    // const handleOptionSelected = (e, name) => {
    //     const { value } = e;
    //     const list = { ...data };
    //     list[name] = value;
    //     setData(list);
    // }

    const submitForm = async () => {
        let check = true;
        let error = { name: "" }
        if (!data.name) {
            check = false;
            error.name = translate(lang, "errorRequired");
        }
        setError({ ...error });
        if (check) {
            data.type = gamePlayTypeOptions.find(opt => opt.value === gameType).value;
            let res = '';
            if (type === 'create') {
                res = await createGamePlay(data);
            } else if (type === 'edit') {
                let temp = {
                    type: data.type,
                    name: data.name,
                    description: data.description,
                    markings: data.markings,
                }
                res = await editGamePlay(temp, id);
            }
            if (res.status === 'success') {
                Swal.fire({
                    title: translate(lang, 'success'),
                    html: `<p class="text-uppercase">${res.message}</p>`,
                    icon: 'success',
                    timer: 1500,
                }).then(() => history.push('/settings/game'))
                resetForm();
            } else {
                Swal.fire({
                    title: translate(lang, 'error'),
                    html: `<p class="text-uppercase">${res.message}</p>`,
                    icon: 'error',
                })
            }
        } else {
            promptMessage(lang, 'error', translate(lang, "formErrorMessage"));
        }
    }

    const resetForm = async () => {
        setData({
            name: "",
            description: "",
            handicap: false,
        })
    }

    const handleMarkings = async (type, index) => {
        let marks = data.markings;
        if (type === 'delete') {
            if (index < 0) {
                return;
            }
            marks.splice(index, 1);
        } else if (type === 'add_positive') {
            marks.push({
                value: marks[marks.length - 1].value + 1,
                result: marks[marks.length - 1].result + 1,
                description: '',
                name: '',
            })
        } else if (type === 'add_negative') {
            marks.unshift({
                value: marks[0].value - 1,
                result: marks[0].result - 1,
                description: '',
                name: '',

            })
        }
        setData({
            ...data, markings: marks,
        })
    }

    function updateMarks(updated) {
        let temp = list;
        temp.items = temp.items.map(item => {
            if (item.id === updated.id) {
                item.value = parseInt(updated.point)
            }
            return item
        })
        setList({
            ...temp,
            items: [...temp.items],
        })
    }

    function updateMarksError(e) {
        console.log(e);
    }

    return (
        <div className="d-flex flex-column flex-lg-row gap-3">
            <div className="card shadow col-12 col-lg-4">
                <div className="card-header">
                    <h3 className="m-0">
                        {translate(lang, (type === "view" ? "gamePlayDetails" : type === "edit" ? "editGamePlay" : "createGamePlay"))}
                    </h3>
                </div>
                <div className="card-body col-12 d-flex flex-column">
                    <div className="d-flex flex-column flex-md-row gap-2">
                        <div className="form-group py-2 col-12">
                            <label className="form-label">{translate(lang, 'type')}</label>
                            <input type="text" value={data.type} className="form-control" disabled={true} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-md-row gap-2">
                        <div className="form-group py-2 col-12">
                            <label className="form-label">{translate(lang, 'name')}</label>
                            <span className="ms-2 text-danger">*</span>
                            <input type="text" value={data.name} onChange={(e) => { setData({ ...data, name: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                            {error.name === "" ? <></> : <p className="text-danger">{error.name}</p>}
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-md-row gap-2">
                        <div className="form-group py-2 col-12">
                            <label className="form-label">{translate(lang, 'description')}</label>
                            <input type="text" value={data.description} onChange={(e) => { setData({ ...data, description: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                        </div>
                    </div>
                    {gameType !== 'game_type' ? <></> :
                        <div className="d-flex flex-column flex-md-row gap-2">
                            <div className="form-group py-2 col-12">
                                <label className="form-label">{translate(lang, 'handicap')}</label>
                                <Checkbox checked={data.handicap} readOnly />
                            </div>
                        </div>
                    }
                </div>
                <div className="card-footer">
                    <div className="d-flex flex-row">
                        <button type="button" hidden={type === 'view' ? true : false} className="btn btn-primary btn-sm" onClick={() => submitForm()}>{translate(lang, `${type === 'edit' ? 'save' : type}`)}</button>
                        <button type="button" className="btn btn-dark mx-2 btn-sm" onClick={() => history.push('/settings/game')}>{translate(lang, "back")}</button>
                    </div>
                </div>
            </div>
            <div className="card shadow col-12 col-lg-8">
                <div className="card-header">
                    <h3 className="m-0">
                        {translate(lang, 'markingsAndFormula')}
                    </h3>
                </div>
                <div className="card-body col-12 d-flex flex-column gap-4">
                    <div className="d-flex flex-column flex-md-row gap-2">
                        <div className="d-flex flex-column">
                            <h5>{translate(lang, 'formula')}</h5>
                            {data.calculation && data.calculation.length && data.calculation.map((c, index) => (
                                <b className="text-uppercase">{index + 1}. {unsligify(c === '' ? '-' : `${c} = ${index === data.calculation.length - 1 ? translate(lang, 'finalResult') : translate(lang, 'result')}`, [{ old: '%', new: '' }, { old: '_', new: ' ' }])}</b>
                            ))}
                        </div>
                    </div>
                    <div>
                        <div className="d-flex flex-row gap-2 align-items-center mb-2">
                            <h5 className="m-0">{translate(lang, 'markings')}</h5>
                            {/* <button className="btn btn-sm btn-danger" onClick={() => handleMarkings('add_negative')}>{translate(lang, 'addNegative')}</button>
                            <button className="btn btn-sm btn-secondary" onClick={() => handleMarkings('add_positive')}>{translate(lang, 'addPositive')}</button> */}
                        </div>
                        <DataGrid
                            getRowId={(row, index) => row.id}
                            rows={[...list.items]}
                            columns={[...list.headers]}
                            slots={{
                                footer: (props) => <></>,
                            }}
                            processRowUpdate={(updatedRow, originalRow) => {
                                updateMarks(updatedRow)
                                return updatedRow
                            }}
                            onProcessRowUpdateError={updateMarksError}
                            isCellEditable={(params) => !['condition'].includes(params.id) ? true : false}
                        />
                        {/* <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>{translate(lang, 'points')}</th>
                                    <th>{translate(lang, 'result')}</th>
                                    <th>{translate(lang, 'markingName')}</th>
                                    <th>{translate(lang, 'description')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.calculation !== '' && data.markings ? data.markings.map((mark, index) => (
                                    <tr>
                                        {
                                            
                                            <td>{mark.value}</td>
                                        }
                                        <td>{mark.result}{data.markings.length > 1 && (index === 0 || index === data.markings.length - 1) ? " (+)" : ""}</td>
                                        <td className="text-capitalize">{unsligify(mark.name)}</td>
                                        <td>{mark.description}</td>
                                    </tr>
                                )) : (
                                    <tr><td colSpan={3}>{translate(lang, 'noMarkingFound')}</td></tr>
                                )}
                            </tbody>
                        </table> */}
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(GamePlayForm);