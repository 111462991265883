import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { translate } from "../../../utils/translate";
import Select from 'react-select';
import { genderOptions, promptMessage, showLoader, userRoleOptions } from "../../../utils/functions";
import { createUser, editUser, getUserById } from "../../../services/ApiService";
import Swal from "sweetalert2";


const UserForm = (props) => {
    const lang = props.lang;
    const { auth } = props.auth;
    const { type, id } = useParams();
    const userRoleListOptions = userRoleOptions(props.lang, auth.data.role);
    const genderListOptions = genderOptions(props.lang);
    const history = useHistory();
    const [data, setData] = useState({
        name: "",
        gender: "male",
        date_of_birth: "",
        email: "",
        username: "",
        role: "agent",
        phone: "",
        password: "",
        confirmPassword: "",
    });
    const [error, setError] = useState({
        name: "",
        gender: "",
        email: "",
        username: "",
        role: "",
        phone: "",
        password: "",
        confirmPassword: "",
    });

    useEffect(() => {
        if (type === 'create') {
            setData({
                name: "",
                gender: "male",
                date_of_birth: "",
                email: "",
                username: "",
                role: "agent",
                phone: "",
                password: "",
                confirmPassword: "",
            })
        } else if (type === 'view') {
            if (!id) {
                history.push('/user');
                return;
            }
            getDetails(id);
        } else if (type === 'edit') {
            if (!id) {
                history.push('/user');
                return;
            }
            getDetails(id);
        }
    }, [])

    const getDetails = async (id) => {
        const res = await getUserById(id)
        if (res.status === 'success') {
            let temp = data;
            Object.assign(temp, res.data);
            setData({ ...temp })
        } else {
            history.push('/user');
            return;
        }
    }

    const handleOptionSelected = (e, name) => {
        const { value } = e;
        const list = { ...data };
        list[name] = value;
        setData(list);
    }

    const submitForm = async () => {
        await showLoader(lang, true);
        let check = true;
        let error = { name: "", gender: "", role: "", username: "", }
        if (!data.name) {
            check = false;
            error.name = translate(lang, "errorRequired");
        }
        if (!data.role) {
            check = false;
            error.role = translate(lang, "errorRequired");
        }
        if (!data.gender) {
            check = false;
            error.gender = translate(lang, "errorRequired");
        }
        if (type === 'create') {
            if (!data.username) {
                check = false;
                error.username = translate(lang, "errorRequired");
            }
            // if (!data.password) {
            //     check = false;
            //     error.password = translate(lang, "errorRequired");
            // }
            // if (!data.confirmPassword) {
            //     check = false;
            //     error.confirmPassword = translate(lang, "errorRequired");
            // }
        }
        setError({ ...error });
        if (check) {
            let res;
            if (type === 'create') {
                res = await createUser(data);
            } else {
                res = await editUser(id, data);
            }
            await showLoader(lang, false);
            if (res.status === 'success') {
                Swal.fire({
                    title: translate(lang, 'success'),
                    html: `<p class="text-uppercase">${res.message}</p>`,
                    icon: 'success',
                    timer: 1500,
                }).then(() => history.push('/user'))
                resetForm();
            } else {
                Swal.fire({
                    title: translate(lang, 'error'),
                    html: `<p class="text-uppercase">${res.message}</p>`,
                    icon: 'error',
                })
            }
        } else {
            promptMessage(lang, 'error', translate(lang, "formErrorMessage"));
        }
    }

    const resetForm = async () => {
        setData({
            name: "",
            gender: "",
            date_of_birth: "",
            email: "",
            username: "",
            role: "agent",
            phone: "",
            password: "",
            confirmPassword: "",
        })
    }


    return (
        <>
            <div className="card shadow col-12 col-md-6">
                <div className="card-header">
                    <h3 className="m-0">
                        {translate(lang, (type === "view" ? "userDetails" : type === "edit" ? "editUser" : "addUser"))}
                    </h3>
                </div>
                <div className="card-body col-12 d-flex flex-column">
                    <div className="form-group py-2">
                        <label className="form-label">{translate(lang, 'fullName')}</label>
                        <span className="ms-2 text-danger">*</span>
                        <input type="text" value={data.name} onChange={(e) => { setData({ ...data, name: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                        {error.name === "" ? <></> : <p className="text-danger">{error.name}</p>}
                    </div>
                    <div className="form-group py-2">
                        <label className="form-label">{translate(lang, 'gender')}</label>
                        <span className="ms-2 text-danger">*</span>
                        <Select
                            className="form-control"
                            value={genderListOptions.filter(
                                (option) => option.value === data.gender
                            )}
                            isDisabled={type === 'view' ? true : false}
                            onChange={(e) => handleOptionSelected(e, "gender")}
                            options={genderListOptions}
                            placeholder={translate(lang, "placeholderChoose")}
                            styles={{
                                control: (base, state) => ({
                                    ...base,
                                    height: "100%",
                                    border: 0,
                                    borderColor: "transparent",
                                    boxShadow: "none",
                                    "&:hover": {
                                        borderColor: "transparent",
                                        boxShadow: "none",
                                    },
                                    backgroundColor: "transparent",
                                }),
                                valueContainer: (base, state) => ({
                                    ...base,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    borderColor: "transparent",
                                })
                            }}
                        />
                        {error.gender === "" ? <></> : <p className="text-danger">{error.gender}</p>}
                    </div>
                    <div className="form-group py-2">
                        <label className="form-label">{translate(lang, 'birthDate')}</label>
                        <input type="date" value={data.date_of_birth} onChange={(e) => { setData({ ...data, date_of_birth: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                    </div>
                    <div className="form-group py-2">
                        <label className="form-label">{translate(lang, 'phone')}</label>
                        <input type="text" value={data.phone} onChange={(e) => { setData({ ...data, phone: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                    </div>
                    <div className="form-group py-2">
                        <label className="form-label">{translate(lang, 'email')}</label>
                        <input type="email" value={data.email} onChange={(e) => { setData({ ...data, email: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                    </div>
                    <div className="form-group py-2">
                        <label className="form-label">{translate(lang, 'role')}</label>
                        <span className="ms-2 text-danger">*</span>
                        <Select
                            className="form-control"
                            value={userRoleListOptions.filter(
                                (option) => option.value === data.role
                            )}
                            isDisabled={type === 'view' ? true : false}
                            onChange={(e) => handleOptionSelected(e, "role")}
                            options={userRoleListOptions}
                            placeholder={translate(lang, "placeholderChoose")}
                            styles={{
                                control: (base, state) => ({
                                    ...base,
                                    height: "100%",
                                    border: 0,
                                    borderColor: "transparent",
                                    boxShadow: "none",
                                    "&:hover": {
                                        borderColor: "transparent",
                                        boxShadow: "none",
                                    },
                                    backgroundColor: "transparent",
                                }),
                                valueContainer: (base, state) => ({
                                    ...base,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    borderColor: "transparent",
                                })
                            }}
                        />
                        {error.title === "" ? <></> : <p className="text-danger">{error.role}</p>}
                    </div>
                    {type === 'create' ? <>
                        <div className="form-group py-2">
                            <label className="form-label">{translate(lang, 'username')}</label>
                            <span className="ms-2 text-danger">*</span>
                            <input type="text" value={data.username} onChange={(e) => { setData({ ...data, username: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                            {error.username === "" ? <></> : <p className="text-danger">{error.username}</p>}
                        </div>
                        <div className="form-group py-2">
                            <label className="form-label">{translate(lang, 'password')}</label>
                            <input type="password" value={data.password} onChange={(e) => { setData({ ...data, password: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                        </div>
                        <div className="form-group py-2">
                            <label className="form-label">{translate(lang, 'confirmPassword')}</label>
                            <input type="password" value={data.confirmPassword} onChange={(e) => { setData({ ...data, confirmPassword: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                        </div>
                    </> : <></>}
                </div>
                <div className="card-footer">
                    <div className="d-flex flex-row">
                        <button type="button" hidden={type === 'view' ? true : false} className="btn btn-primary btn-sm" onClick={() => submitForm()}>{translate(lang, `${type === 'edit' ? 'save' : type}`)}</button>
                        <button type="button" className="btn btn-dark mx-2 btn-sm" onClick={() => history.push('/user')}>{translate(lang, "back")}</button>
                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(UserForm);