import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { translate } from "../../../utils/translate";
import { promptMessage } from "../../../utils/functions";
import { createCourse, editCourse, getMapDetails } from "../../../services/ApiService";
import Swal from "sweetalert2";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

const CourseForm = (props) => {
    const lang = props.lang;
    const { type, mapId } = useParams();
    const history = useHistory();
    const [currentAccordion, setCurrentAccordion] = useState(0);
    const [data, setData] = useState([]);
    const [ratings, setRatings] = useState([]);
    const [error, setError] = useState([]);

    useEffect(() => {
        getMapData();
        if (type === 'create') {
        } else if (type === 'view') {
            if (!mapId) {
                history.push(`/map/`)
                return;
            }
            // getDetails(id);
        } else if (type === 'edit') {
            if (!mapId) {
                history.push(`/map/`);
                return;
            }
            // getDetails(id);
        }
    }, [])

    const getMapData = async () => {
        const res = await getMapDetails(mapId);
        if (res?.status === 'success') {
            let tempArr = [];
            let tempErr = [];
            res.data.courses.map((course, index) => {
                tempArr.push({
                    id: course.id,
                    hole_no: course.hole_no,
                    par: course.par,
                    difficulty: course.difficulty,
                    course_distances: course.course_distances,
                })
                tempErr.push({ par: "", difficulty: "", distance: "" })
                return true;
            })
            setRatings([...res.data.ratings])
            setData([...tempArr]);
            setError([...tempErr])
            // setData({
            //     mapId: parseInt(mapId),
            //     name: res.data.name, // map name
            //     hole_no: res.data.courses.length + 1,
            //     par: 1,
            //     difficulty: res.data.courses.length + 1,
            //     range: map_rating.map(rating => ({
            //         color: rating.color,
            //         description: rating.description,
            //         distance: 0,
            //     }))
            // })
        } else {
            promptMessage(lang, 'warning', res.message)
            return;
        }
    }

    // const getDetails = async (id) => {
    //     // get details then join map get number of rate
    //     const res = await getCoursesById(id);
    //     if (res.status === 'success') {
    //         let temp = {
    //             mapId: parseInt(res.data.map.id),
    //             name: res.data.map.name, // map name
    //             hole_no: res.data.hole_no,
    //             par: res.data.par,
    //             difficulty: res.data.difficulty,
    //         }
    //         if (res.data.course_distances.length > 0) {
    //             temp.range = res.data.course_distances.map(distance => ({
    //                 id: distance.id,
    //                 color: distance.color,
    //                 description: res.data.map.ratings.find(rate => rate.color === distance.color)?.description,
    //                 distance: distance.distance,
    //             }))
    //         }
    //         setData({ ...temp })
    //     } else {
    //         promptMessage(lang, 'warning', res.message)
    //     }
    // }

    // const ratingFormOnChange = (e, index) => {
    //     const { value, name } = e.target;
    //     let tempArr = data;
    //     tempArr[index][name] = value;
    //     setData({ ...data, ratings: tempArr })
    // }

    const courseFormOnChange = (e, index) => {
        const { value, name } = e.target;
        let tempArr = data;
        tempArr[index][name] = value;
        setData([...tempArr]);
    }

    const distanceFormOnChange = (e, index, d_index) => {
        const { value, name } = e.target;
        let tempArr = data;
        tempArr[index].course_distances[d_index][name] = value;
        setData([...tempArr]);
    }

    const checkCourseForm = async () => {
        let tempErr = error;
        let isError = false;
        data.map((course, index) => {
            if (!course.par) {
                isError = true;
                tempErr[index].par = translate(lang, "errorRequired");
            }
            if (!course.difficulty) {
                isError = true;
                tempErr[index].difficulty = translate(lang, "errorRequired");
            }
            if (data.find(dt => parseInt(dt.difficulty) === parseInt(course.difficulty) && dt.hole_no !== course.hole_no)) {
                isError = true;
                tempErr[index].difficulty = translate(lang, "duplicateIndex");
            }
            let c_d = course.course_distances.find(distance => !distance.distance || distance.distance < 1 || distance.distance === '');
            if (c_d) {
                isError = true;
                tempErr[index].distance = translate(lang, "errorRequired");
            }
            return true;
        })
        return { tempErr, isError };
    }

    const submitForm = async () => {
        promptMessage(lang, 'info', translate(lang, 'validateForm'), {
            didOpen: () => {
                Swal.showLoading()
            },
            didDestroy: () => {
                Swal.hideLoading();
            },
        })
        let check = true;
        let error = { par: "", hole_no: "", difficulty: "", range: "" }
        if ((!data || data.length < 1)) {
            check = false;
            error = translate(lang, "errorRequired");
        } else if (data.length > 0) {
            let check_result = await checkCourseForm();
            if (check_result.isError) {
                check = false;
                error = check_result.tempErr;
            }
        }

        setError({ ...error });
        if (check) {
            let tempData = data.map(dt => {
                dt.par = parseInt(dt.par);
                dt.hole_no = parseInt(dt.hole_no);
                dt.difficulty = parseInt(dt.difficulty);
                return dt;
            })
            Swal.close();
            let res;
            if (type === 'create') {
                res = await createCourse(tempData);
            } else {
                res = await editCourse(mapId, tempData);
            }
            if (res.status === 'success') {
                Swal.fire({
                    title: translate(lang, 'success'),
                    html: `<p class="text-uppercase">${res.message}</p>`,
                    icon: 'success',
                    timer: 1500,
                }).then(() => history.push(`/map/course/${mapId}`))
                resetForm();
            } else {
                Swal.fire({
                    title: translate(lang, 'error'),
                    html: `<p class="text-uppercase">${res.message}</p>`,
                    icon: 'error',
                })
            }
        } else {
            promptMessage(lang, 'error', translate(lang, "formErrorMessage"));
        }
    }

    const resetForm = async () => {
        setData({
            name: "",
        })
    }


    return (
        <>
            <div className="card shadow col-12">
                <div className="card-header">
                    <h3 className="m-0">
                        {translate(lang, (type === "view" ? "courseDetails" : type === "edit" ? "editCourse" : "addCourse"))}
                    </h3>
                </div>
                <div className="card-body col-12 d-flex flex-column gap-2">
                    {data.length ? data.map((course, index) => {
                        return (
                            <Accordion key={index + "_course"} expanded={currentAccordion === index} onChange={() => setCurrentAccordion(index)}>
                                <AccordionSummary
                                    expandIcon={<GridExpandMoreIcon />}
                                    style={{
                                        backgroundColor: '#f0f0f0'
                                    }}
                                >
                                    <h5 className="m-0 text-center gap-4 d-flex ">
                                        <span>{translate(lang, "holeNo")} : {course.hole_no}</span>
                                        <span className="d-none d-md-block">{translate(lang, "par")} : {course.par}</span>
                                        <span className="d-none d-md-block">{translate(lang, "difficultyIndex")} : {course.difficulty}</span>
                                    </h5>
                                    {error[index] && (error[index].par !== '' || error[index].difficulty !== '' || error[index].distance !== '') ?
                                        <span className="text-danger ms-3 h5 m-0"><FontAwesomeIcon icon={faTriangleExclamation}></FontAwesomeIcon></span>
                                        : <></>
                                    }
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="d-flex flex-column gap-2">
                                        {/* <p>{JSON.stringify(error.courses)}</p> */}
                                        <div className="d-flex flex-column flex-md-row gap-3">
                                            <div className="form-group py-2">
                                                <label className="form-label">{translate(lang, 'holeNo')}</label>
                                                <input type="number" value={course.hole_no} className="form-control" onWheel={(e) => e.target.blur()} disabled={true} readOnly={true} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                            </div>
                                            <div className="form-group py-2">
                                                <label className="form-label">{translate(lang, 'par')}</label>
                                                <span className="ms-2 text-danger">*</span>
                                                <input type="number" name="par" onChange={(e) => courseFormOnChange(e, index)} value={course.par} className="form-control" onWheel={(e) => e.target.blur()} disabled={type === 'view' ? true : false} readOnly={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                                {error === "" && error.length < 1 && typeof error !== 'string' ? <></> :
                                                    <p className="text-danger">{error[index]?.par}</p>
                                                }
                                            </div>
                                            <div className="form-group py-2">
                                                <label className="form-label">{translate(lang, 'difficultyIndex')}</label>
                                                <span className="ms-2 text-danger">*</span>
                                                <input type="number" name="difficulty" onChange={(e) => courseFormOnChange(e, index)} value={course.difficulty} className="form-control" onWheel={(e) => e.target.blur()} disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                                {error === "" && error.length < 1 && typeof error !== 'string' ? <></> :
                                                    <p className="text-danger">{error[index]?.difficulty}</p>
                                                }
                                            </div>
                                        </div>
                                        <div className="">
                                            <label className="form-label">{translate(lang, 'distance')}</label>
                                            <span className="ms-2 text-danger">*</span>
                                            {error === "" && error.length < 1 && typeof error !== 'string' ? <></> :
                                                <p className="text-danger">{error[index]?.distance}</p>
                                            }
                                            <div className="d-flex flex-column flex-md-row gap-2 px-0 px-md-3 overflow-auto">
                                                {course.course_distances ? course.course_distances.map((distance, d_index) => {
                                                    return <div className="card" key={d_index}>
                                                        <div className="card-body d-flex flex-column gap-2">
                                                            <label className="form-label">{translate(lang, 'color')}: <b className="box-color" style={{ backgroundColor: distance.color }}><span className="box-color-text">{distance.color}</span></b></label>
                                                            {/* <label className="form-label">{translate(lang, 'color')}: <span className="box-color" style={{backgroundColor: distance.color}}></span> <b>{distance.color}</b></label> */}
                                                            <label className="form-label">{translate(lang, 'description')}: <b className="text-capitalize">{ratings.find(rate => rate.color === distance.color) ? ratings.find(rate => rate.color === distance.color).description : ""}</b></label>
                                                            <input type="number" name="distance" onChange={(e) => distanceFormOnChange(e, index, d_index)} value={distance.distance} className="form-control" onWheel={(e) => e.target.blur()} disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                                        </div>
                                                    </div>
                                                }) : <><p>{translate(lang, 'addRatingForDistance')}</p></>}
                                            </div>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })
                        : (typeof error === 'string' && error !== "") ? <><p>{error}</p></>
                            : <><p>{translate(lang, 'nothingToShowHere')}</p></>}
                </div>
                <div className="card-footer">
                    <div className="d-flex flex-row">
                        {!data.length || data.length < 1 ? <></> :
                            <>
                                <button type="button" hidden={type === 'view' ? true : false} className="btn btn-primary btn-sm" onClick={() => submitForm()}>{translate(lang, `${type === 'edit' ? 'save' : type}`)}</button>
                            </> 
                        }
                        <button type="button" className="btn btn-dark mx-2 btn-sm" onClick={() => history.push(`/map/course/${mapId}`)}>{translate(lang, "back")}</button>
                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(CourseForm);