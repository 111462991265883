import { basicAxiosInstance } from '../services/AxiosInstance';

export async function getUserList(params) {
    return basicAxiosInstance.get(`/user/get_users`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function getUserById(id) {
    return basicAxiosInstance.get(`/user/get_user/${id}`
    ).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function createUser(body) {
    return basicAxiosInstance.post(`/user/add_user`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function editUser(id, body) {
    return basicAxiosInstance.put(`/user/edit_user/${id}`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function removeUser(id, body) {
    return basicAxiosInstance.put(`/user/remove/${id}`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function getMapList(params) {
    return basicAxiosInstance.get(`/map/get_maps`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function getMapDetails(id, params) {
    return basicAxiosInstance.get(`/map/get_map/${id}`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function createMap(body) {
    return basicAxiosInstance.post(`/map/add_map`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function editMap(id, body) {
    return basicAxiosInstance.put(`/map/edit_map/${id}`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function removeMap(id, body) {
    return basicAxiosInstance.put(`/map/remove/${id}`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function getCoursesList(params) {
    return basicAxiosInstance.get(`/map/get_courses`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function getCoursesById(id) {
    return basicAxiosInstance.get(`/map/get_course/${id}`,)
    .then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function createCourse(body) {
    return basicAxiosInstance.post(`/map/add_course`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function editCourse(mapId, body) {
    return basicAxiosInstance.put(`/map/edit_course/${mapId}`, [...body,])
    .then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function removeCourse(id, body) {
    return basicAxiosInstance.put(`/map/remove_course/${id}`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function getTournamentList(params) {
    return basicAxiosInstance.get(`/tournament/get_tournaments`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function getTournamentById(id) {
    return basicAxiosInstance.get(`/tournament/get_tournament/${id}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function getTournamentRatingsById(id) {
    return basicAxiosInstance.get(`/tournament/get_tournament_ratings/${id}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function createTournament(body) {
    return basicAxiosInstance.post(`/tournament/add_tournament`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function editTournament(body, id) {
    return basicAxiosInstance.put(`/tournament/edit_tournament/${id}`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function updateTournamentMarks(body, id) {
    return basicAxiosInstance.put(`/tournament/update_tournament_markings/${id}`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function updateTournamentStatus(id, status) {
    return basicAxiosInstance.put(`/tournament/tournament_status/${id}/${status}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function removeTournament(id) {
    return basicAxiosInstance.put(`/tournament/remove/${id}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getGamePlayList(params) {
    return basicAxiosInstance.get(`/game/get_games`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function getGamePlayFormulaList() {
    return basicAxiosInstance.get(`/game/get_games_formula`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function getGamePlayById(id) {
    return basicAxiosInstance.get(`/game/get_game/${id}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function createGamePlay(body) {
    return basicAxiosInstance.post(`/game/add_game`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function editGamePlay(body, id) {
    return basicAxiosInstance.put(`/game/edit_game/${id}`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function editGamePlayStatus(id, status) {
    return basicAxiosInstance.put(`/game/edit_game/${id}/${status}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function removeGamePlay(id) {
    return basicAxiosInstance.put(`/game/remove/${id}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getTournamentPlayerList(id, params) {
    return basicAxiosInstance.get(`/tournament/get_players/${id}`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function getTournamentPlayerById(tId, id) {
    return basicAxiosInstance.get(`/tournament/get_players/${tId}/${id}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function createTournamentPlayer(id, body) {
    return basicAxiosInstance.post(`/tournament/add_player/${id}`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function editTournamentPlayer(id, player_id, body) {
    return basicAxiosInstance.put(`/tournament/edit_player/${id}/${player_id}`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function removeTournamentPlayer(tId, id) {
    return basicAxiosInstance.put(`/tournament/remove_player/${tId}/${id}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getMemberList(params) {
    return basicAxiosInstance.get(`/user/get_members`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function getMemberById(id, params) {
    return basicAxiosInstance.get(`/user/get_member/${id}`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function createMember(body) {
    return basicAxiosInstance.post(`/user/add_member`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function editMember(id, body) {
    return basicAxiosInstance.put(`/user/edit_member/${id}`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function removeMember(id, body) {
    return basicAxiosInstance.put(`/user/remove_member/${id}`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}