import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { createMember, editMember, getMemberById } from "../../../services/ApiService";
import { genderOptions, promptMessage, showLoader } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import Select from 'react-select';
import Swal from "sweetalert2";


const MemberForm = (props) => {
    const history = useHistory();
    const lang = props.lang;
    const { type, id } = useParams();
    const genderListOptions = genderOptions(props.lang);
    const [data, setData] = useState({
        memberId: "",
        name: "",
        gender: "male",
        date_of_birth: "",
        email: "",
        phone: "",
    });
    const [error, setError] = useState({
        name: "",
        gender: "",
        date_of_birth: "",
        email: "",
        phone: "",
    });

    useEffect(() => {
        if (type === 'create') {
            setData({
                memberId: "",
                name: "",
                gender: "male",
                date_of_birth: "",
                email: "",
                phone: "",
            })
        } else if (type === 'view') {
            if (!id) {
                history.push('/member');
                return;
            }
            getDetails(id);
        } else if (type === 'edit') {
            if (!id) {
                history.push('/member');
                return;
            }
            getDetails(id);
        }
    }, [])

    const getDetails = async (id) => {
        const res = await getMemberById(id)
        if (res.status === 'success') {
            let temp = data;
            Object.assign(temp, res.data);
            setData({ ...temp })
        } else {
            history.push('/member');
            return;
        }
    }

    const handleOptionSelected = (e, name) => {
        const { value } = e;
        const list = { ...data };
        list[name] = value;
        setData(list);
    }

    const submitForm = async (another = false) => {
        showLoader(lang, true);
        let check = true;
        let error = { name: "", gender: "", date_of_birth: "", email: "", phone: "", };
        if (!data.name) {
            check = false;
            error.name = translate(lang, "errorRequired")
        }
        if (!data.gender) {
            check = false;
            error.gender = translate(lang, "errorRequired")
        }
        if (!data.date_of_birth) {
            check = false;
            error.date_of_birth = translate(lang, "errorRequired")
        }
        if (!data.email) {
            check = false;
            error.email = translate(lang, "errorRequired")
        }
        if (!data.phone) {
            check = false;
            error.phone = translate(lang, "errorRequired")
        }
        setError({ ...error });
        if (check) {
            let res;
            if (type === 'create') {
                res = await createMember(data);
            } else if (type === 'edit') {
                res = await editMember(id, data);
            }
            showLoader(lang, false);
            if (res.status === 'success') {
                Swal.fire({
                    title: translate(lang, 'success'),
                    html: `<p class="text-uppercase">${res.message}</p>`,
                    icon: 'success',
                    timer: 1500,
                }).then(() => {
                    if (!another) {
                        history.push('/member')
                    }
                })
                resetForm();
            } else {
                Swal.fire({
                    title: translate(lang, 'error'),
                    html: `<p class="text-uppercase">${res.message}</p>`,
                    icon: 'error',
                })
            }
        } else {
            promptMessage(lang, 'error', translate(lang, "formErrorMessage"));
        }
    }

    const resetForm = async () => {
        setData({
            memberId: "",
            name: "",
            gender: "",
            date_of_birth: "",
            email: "",
            phone: "",
        })
    }

    return (
        <>
            <div className="d-flex flex-column flex-md-row">
                <div className="col-12 col-md-6 px-2">
                    <div className="card col-12">
                        <div className="card-header">
                            <h3 className="m-0">
                                {translate(lang, (type === "view" ? "memberDetails" : type === "edit" ? "editMember" : "addMember"))}
                            </h3>
                        </div>
                        <div className="card-body col-12">
                            <div className="col-12 d-flex flex-row flex-wrap">
                                <div className="form-group py-2 col-12 col-md-6 px-2">
                                    <label className="form-label">{translate(lang, 'memberId')}</label>
                                    <input type="text" value={data.memberId} className="form-control" onChange={(e) => { setData({ ...data, memberId: e.target.value }) }} disabled={type === 'create' ? false : true} readOnly={type === 'create' ? false : true} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                </div>
                                <div className="form-group py-2 col-12 col-md-6 px-2">
                                    <label className="form-label">{translate(lang, 'fullName')}</label>
                                    <span className="ms-2 text-danger">*</span>
                                    <input type="text" value={data.name} onChange={(e) => { setData({ ...data, name: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                    {error.name === "" ? <></> : <p className="text-danger">{error.name}</p>}
                                </div>
                                <div className="form-group py-2 col-12 col-md-6 px-2">
                                    <label className="form-label">{translate(lang, 'gender')}</label>
                                    <span className="ms-2 text-danger">*</span>
                                    <Select
                                        className="form-control"
                                        value={genderListOptions.filter(
                                            (option) => option.value === data.gender
                                        )}
                                        isDisabled={type === 'view' ? true : false}
                                        onChange={(e) => handleOptionSelected(e, "gender")}
                                        options={genderListOptions}
                                        placeholder={translate(lang, "placeholderChoose")}
                                        styles={{
                                            control: (base, state) => ({
                                                ...base,
                                                height: "100%",
                                                border: 0,
                                                borderColor: "transparent",
                                                boxShadow: "none",
                                                "&:hover": {
                                                    borderColor: "transparent",
                                                    boxShadow: "none",
                                                },
                                                backgroundColor: "transparent",
                                            }),
                                            valueContainer: (base, state) => ({
                                                ...base,
                                                paddingLeft: 0,
                                                paddingRight: 0,
                                                borderColor: "transparent",
                                            })
                                        }}
                                    />
                                    {error.gender === "" ? <></> : <p className="text-danger">{error.gender}</p>}
                                </div>
                                <div className="form-group py-2 col-12 col-md-6 px-2">
                                    <label className="form-label">{translate(lang, 'birthDate')}</label>
                                    <span className="ms-2 text-danger">*</span>
                                    <input type="date" value={data.date_of_birth} onChange={(e) => { setData({ ...data, date_of_birth: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                    {error.date_of_birth === "" ? <></> : <p className="text-danger">{error.date_of_birth}</p>}
                                </div>
                                <div className="form-group py-2 col-12 col-md-6 px-2">
                                    <label className="form-label">{translate(lang, 'email')}</label>
                                    <span className="ms-2 text-danger">*</span>
                                    <input type="email" value={data.email} onChange={(e) => { setData({ ...data, email: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                    {error.email === "" ? <></> : <p className="text-danger">{error.email}</p>}
                                </div>
                                <div className="form-group py-2 col-12 col-md-6 px-2">
                                    <label className="form-label">{translate(lang, 'phone')}</label>
                                    <span className="ms-2 text-danger">*</span>
                                    <input type="tel" value={data.phone} onChange={(e) => { setData({ ...data, phone: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                    {error.phone === "" ? <></> : <p className="text-danger">{error.phone}</p>}
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="d-flex flex-row">
                                <button type="button" hidden={type === 'view' ? true : false} className="btn btn-primary btn-sm" onClick={() => submitForm()}>{translate(lang, `${type === 'edit' ? 'save' : type}`)}</button>
                                {type === 'create' ?
                                    <button type="button" hidden={type === 'view' ? true : false} className="btn btn-secondary mx-2 btn-sm" onClick={() => submitForm(true)}>{translate(lang, `createAnother`)}</button>
                                    : <></>
                                }
                                <button type="button" className="btn btn-dark btn-sm mx-2" onClick={() => history.push('/member')}>{translate(lang, "back")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};

export default connect(mapStateToProps)(MemberForm);