import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { editGamePlayStatus, getGamePlayList, removeGamePlay} from "../../../services/ApiService";
import { promptMessage } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import MuiTable from "../../components/MuiTable";
import Switch from '@mui/material/Switch';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMarker, faTrashCan } from "@fortawesome/free-solid-svg-icons";

const GamePlaySettings = (props) => {
    const lang = props.lang;
    const history = useHistory();
    const [searchText, setSearchText] = useState('');
    const [listGameType, setListGameType] = useState({
        items: [],
        headers: [
            { field: 'name', headerName: translate(lang, 'name'), restricted: [], flex: 1, minWidth: 150, },
            // { field: 'description', headerName: translate(lang, 'description'), restricted: [], flex: 2, },
            {
                field: 'status', type: 'actions', headerName: translate(lang, 'status'), restricted: ['agent', 'player'], flex: 1, minWidth: 100, getActions: (params) => [
                    <Switch checked={params.row.status === 'active' ? true : false} onChange={(e) => updateItemStatus(params.id, e)} name="" />
                    // <FormControlLabel
                    //     control={
                    //     }
                    //     label={translate(props.lang, params.row.status === 'active' ? 'active' : 'inactive')}
                    // />,
                ]
            },
            {
                field: 'actions', type: 'actions', headerName: translate(lang, 'action'), restricted: ['agent', 'player'], flex: 2, minWidth: 200, getActions: (params) => [
                    <Link to={`/settings/game/edit/game_type/${params.id}`} title={translate(lang, "save")} className=" btn btn-sm btn-warning cursor-pointer"><FontAwesomeIcon icon={faMarker}></FontAwesomeIcon></Link>,
                    <button onClick={() => removeItem(params.id)} title={translate(lang, "delete")} className="btn btn-sm btn-danger cursor-pointer"><FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon></button>
                ]
            },
        ],
    });
    const [listPlayMode, setListPlayMode] = useState({
        items: [],
        headers: [
            { field: 'name', headerName: translate(lang, 'name'), restricted: [], flex: 1, minWidth: 150, },
            // { field: 'description', headerName: translate(lang, 'description'), restricted: [], flex: 2, },
            {
                field: 'status', type: 'actions', headerName: translate(lang, 'status'), restricted: ['agent', 'player'], flex: 1, minWidth: 100, getActions: (params) => [
                    <Switch checked={params.row.status === 'active' ? true : false} onChange={(e) => updateItemStatus(params.id, e)} name="" />
                    // <FormControlLabel
                    //     control={
                    //     }
                    //     label={translate(props.lang, params.row.status === 'active' ? 'active' : 'inactive')}
                    // />,
                ]
            },
            {
                field: 'actions', type: 'actions', headerName: translate(lang, 'action'), restricted: ['agent', 'player'], flex: 2, minWidth: 200, getActions: (params) => [
                    <Link to={`/settings/game/edit/play_mode/${params.id}`} title={translate(lang, "save")} className=" btn btn-sm btn-warning cursor-pointer"><FontAwesomeIcon icon={faMarker}></FontAwesomeIcon></Link>,
                    <button onClick={() => removeItem(params.id)} title={translate(lang, "delete")} className="btn btn-sm btn-danger cursor-pointer"><FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon></button>,
                ]
            },
        ],
    });
    const exportData = {
        name: "game_play_settings_report",
        excelData: [],
        hasCustom: false,
        customRows: [{
            customData: [],
            origin: -1, // last row or specified location like 'A1'
        }],
    };

    useEffect(() => {
        getList()
    }, [searchText])

    async function getList() {
        let query = {};
        let res = await getGamePlayList(query);
        if (res?.status === 'success') {
            setListGameType({ ...listGameType, items: [...res.data.filter(dt => dt.type === 'game_type')] });
            setListPlayMode({ ...listPlayMode, items: [...res.data.filter(dt => dt.type === 'play_mode')] });
        } else {
        }
    }

    function onTextSearched(value) {
        setSearchText(value);
    }

    function onActionTaken(value) {
        if (value === 'remove') {
            removeItem()
        } else if (value === 'edit') {
            history.push('');
        }
    }

    function updateItemStatus(id, event) {
        let status = event.target.checked ? 'active' : 'inactive';
        Swal.fire({
            title: translate(props.lang, "updateStatusConfirmation"),
            text: translate(props.lang, "updateStatusMessage"),
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await editGamePlayStatus(id, status)
                if (res.status === 'success') {
                    promptMessage(lang, 'success', res.message)
                    getList();
                } else {
                    promptMessage(lang, 'error', res.message ?? "error")
                    getList();
                }
            }
        })
    }

    function removeItem(id) {
        Swal.fire({
            title: translate(props.lang, "deleteConfirmation"),
            text: translate(props.lang, "deleteMessage"),
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await removeGamePlay(id)
                if (res.status === 'success') {
                    promptMessage(lang, 'success', res.message)
                    getList();
                } else {
                    promptMessage(lang, 'error', res.message ?? "error")
                    getList();
                }
            }
        })
    }

    return (
        <>
            <div className="d-flex flex-column flex-md-row gap-2">
                <div className="card shadow col-12 col-md-6">
                    <div className="card-header d-flex">
                        <h5 className="m-0">{translate(lang, 'playMode')}</h5>
                        {/* <div>
                            <Link to="/settings/game/create/play_mode" className="btn btn-primary btn-sm">{translate(lang, 'addPlayMode')}</Link>
                        </div> */}
                    </div>
                    <div className="card-body w-100">
                        <MuiTable list={{ ...listPlayMode }}  role={props.auth.auth.data.role} options={{ search: false, showFooter: false }} lang={props.lang} exportData={exportData} onTextSearched={(e) => onTextSearched(e)} onActionTaken={(e) => onActionTaken(e)}></MuiTable>
                    </div>
                </div>
                <div className="card shadow col-12 col-md-6">
                    <div className="card-header d-flex">
                        <h5 className="m-0">{translate(lang, 'gameType')}</h5>
                        {/* <div>
                            <Link to="/settings/game/create/game_type" className="btn btn-primary btn-sm">{translate(lang, 'addGameType')}</Link>
                        </div> */}
                    </div>
                    <div className="card-body w-100">
                        <MuiTable list={{ ...listGameType }} role={props.auth.auth.data.role} options={{ search: false, showFooter: false }} lang={props.lang} exportData={exportData} onTextSearched={(e) => onTextSearched(e)} onActionTaken={(e) => onActionTaken(e)}></MuiTable>
                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(GamePlaySettings);