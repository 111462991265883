import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { translate } from "../../../utils/translate";
import { findDuplicatesInArray, promptMessage, unsligify } from "../../../utils/functions";
import { createTournament, editTournament, getCoursesList, getGamePlayFormulaList, getGamePlayList, getMapList, getTournamentById, getUserList } from "../../../services/ApiService";
import Swal from "sweetalert2";
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Checkbox, createFilterOptions, listClasses, Tab, Tabs } from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { DataGrid } from '@mui/x-data-grid';

const TournamentForm = (props) => {
    const lang = props.lang;
    const { type, id } = useParams();
    const history = useHistory();
    const [data, setData] = useState({
        title: "",
        game_type: "",
        play_mode: "",
        map_id: "",
        map_name: "",
        pm_id: "",
        gt_id: "",
        pic_id: "",
        start_date: "",
        teeOffTime: "",
        noOfHoles: "",
        handicap_play_off: false,
        ratings: [],
        courses: [],
        markings: [],
    });
    const [userList, setUserList] = useState([]);
    const [mapList, setMapList] = useState([]);
    const [gameTypeList, setGameTypeList] = useState([]);
    const [gameFormulaList, setGameFormulaList] = useState([]);
    const [playModeList, setPlayModeList] = useState([]);
    const [error, setError] = useState({
        name: "",
        game_type: "",
        play_mode: "",
        pic_id: "",
        start_date: "",
        teeOffTime: "",
        map_id: "",
        noOfHoles: "",
        ratings: [],
        courses: [],
    });
    const [currentTab, setCurrentTab] = useState(0);
    const [currentAccordion, setCurrentAccordion] = useState(0);
    const [isHandicapDisabled, setIsHandicapDisabled] = useState(true);
    const [playModeMark, setPlayModeMark] = useState({
        // as play mode list
        items: [],
        headers: [
            {
                field: 'value', headerName: translate(lang, 'point'), flex: 0, minWidth: 50, restricted: [], valueGetter: (params) => {
                    return params.row.value;
                }
            },
            {
                field: 'result', headerName: translate(lang, 'result'), flex: 0, minWidth: 100, restricted: [], valueGetter: (params) => {
                    return params.row.result;
                }
            },
            {
                field: 'name', headerName: translate(lang, 'markingName'), flex: 0.5, minWidth: 100, restricted: [], renderCell: (params) => {
                    return <span className="text-capitalize">{unsligify(params.row.name)}</span>
                }
            },
            { field: 'description', headerName: translate(lang, 'description'), flex: 0.5, minWidth: 200, restricted: [], },
        ],
    });
    const [gameTypeMark, setGameTypeMark] = useState({
        items: [],
        headers: [
            {
                field: 'value', headerName: translate(lang, 'point'), flex: 0, minWidth: 50, restricted: [], valueGetter: (params) => {
                    return params.row.value;
                }, editable: false,
            },
            {
                field: 'result', headerName: translate(lang, 'result'), flex: 0, minWidth: 100, restricted: [], valueGetter: (params) => {
                    return params.row.result;
                }
            },
            {
                field: 'name', headerName: translate(lang, 'markingName'), flex: 0.5, minWidth: 100, restricted: [], renderCell: (params) => {
                    return <span className="text-capitalize">{unsligify(params.row.name)}</span>
                }
            },
            { field: 'description', headerName: translate(lang, 'description'), flex: 0.5, minWidth: 200, restricted: [] },
        ],
    });

    useEffect(() => {
        getUsers();
        getMaps();
        getGames();
        if (type === 'create') {
            setData({
                title: "",
                game_type: "",
                start_date: "",
                teeOffTime: "",
                play_mode: "",
                map_id: "",
                map_name: "",
                pm_id: "",
                gt_id: "",
                handicap_play_off: false,
                noOfHoles: "",
                ratings: [],
                courses: [],
                markings: [],
            })
        } else if (type === 'view') {
            if (!id) {
                history.push('/tournament');
                return;
            }
            getDetails(id);
        } else if (type === 'edit') {
            if (!id) {
                history.push('/tournament');
                return;
            }
            getDetails(id);
        }
    }, [])

    const getUsers = async () => {
        let query = {}
        let res = await getUserList(query);
        if (res?.status === 'success') {
            setUserList([...res.data])
        } else {
        }
    }

    const getMaps = async () => {
        const res = await getMapList();
        if (res.status === 'success') {
            setMapList([...res.data]);
        } else {

        }
    }

    const getGames = async () => {
        let query = {
            status: 'active',
        }
        const res = await getGamePlayList(query);
        if (res.status === 'success') {
            setGameTypeList([...res.data.filter(dt => dt.type === 'game_type')]);
            setPlayModeList([...res.data.filter(dt => dt.type === 'play_mode')]);
            const res2 = await getGamePlayFormulaList(query);
            if (res2.status === 'success') {
                setGameFormulaList([...res2.data]);
            }
        } else {

        }
    }

    const getDetails = async (id) => {
        const res = await getTournamentById(id);
        if (res.status === 'success') {
            setData({
                title: res.data.title,
                game_type: res.data.game_type,
                start_date: res.data.start_date,
                teeOffTime: res.data.tee_off_time,
                play_mode: res.data.play_mode,
                pic_id: res.data.user_id,
                pm_id: "",
                gt_id: "",
                map_id: res.data.tournament_map.id,
                map_name: res.data.tournament_map.name,
                noOfHoles: res.data.tournament_map.numberOfHoles,
                ratings: [...res.data.tournament_map.ratings],
                courses: [...res.data.tournament_map.tournament_courses.map(tc => { tc.course_distances = tc.distance; delete tc.distance; return tc })],
                handicap_play_off: res.data.handicap_play_off,
                markings: [...res.data.event_calculation],
            })
            setError({ ...error, courses: [...Array(res.data.tournament_map.numberOfHoles)].map((err) => ({ par: "", difficulty: "", distance: "" })) })
            let pm = res.data.event_calculation.find(ec => ec.type === 'play_mode')
            let gt = res.data.event_calculation.find(ec => ec.type === 'game_type')
            setPlayModeMark({
                ...playModeMark,
                items: pm.formula.results.map((r, index) => ({ ...r, id: `${pm.type}_${index}` }))
            })
            let gtmHeader = gameTypeMark.headers.map((h) => {
                if (h.field === 'value') {
                    h.editable = gt.formula.editable
                }
                return h;
            })
            setGameTypeMark({
                headers: [...gtmHeader],
                items: gt.formula.results.map((r, index) => ({ ...r, id: `${gt.type}_${index}` }))
            })
        } else {

        }
    }

    const handleMapOptionsChanges = async (e, v) => {
        let query = {
            map_id: v.id,
        }
        const res = await getCoursesList(query);
        if (res.status === 'success') {
            setData({ ...data, map_id: v.id, noOfHoles: v.total_courses, ratings: [...v.ratings], courses: [...res.data] });
            setError({ ...error, courses: [...Array(res.data.length)].map((err) => ({ par: "", difficulty: "", distance: "" })) })
        } else {
            setData({ ...data, map_id: v.id, noOfHoles: v.total_courses, ratings: [...v.ratings] });
        }
    }

    const mapFormOnChange = (e) => {
        let { name, value } = e.target;
        value = parseInt(value);
        let temp = data;
        let tempErr = error;
        if (name === "noOfHoles") {
            temp.noOfHoles = value;
            if (temp.map_id > 0 && temp.courses.length < value) {
                for (let x = temp.courses.length; x < value; x++) {
                    temp.courses.push({
                        hole_no: x + 1,
                        par: 1,
                        difficulty: x + 1,
                        course_distances: temp.ratings.map((rate) => ({ color: rate.color, distance: 100 }))
                    })
                    tempErr.courses.push({ par: "", difficulty: "", distance: "" })
                }
            } else if (temp.map_id > 0 && temp.courses.length > value) {
                temp.courses = temp.courses.splice(0, value);
                tempErr.courses = tempErr.courses.splice(0, value)
            }
        }
        setData({ ...temp })
        setError({ ...tempErr })
    }

    const handleRatingForm = (action, index = -1) => {
        let temp = data;
        if (action === 'add') {
            temp.ratings.push({
                color: '#f0f0f0',
                slopeRating: 0,
                courseRating: 0,
                description: '',
            })
            if (temp.courses && temp.courses.length > 0) {
                temp.courses.map((course) => {
                    course.course_distances.push({
                        color: '#f0f0f0', distance: 100,
                    })
                    return course;
                })
            }
        } else if (action === 'remove') {
            if (index > -1) {
                temp.ratings.splice(index, 1);
            }
            if (temp.courses && temp.courses.length > 0) {
                temp.courses.map((course) => {
                    course.course_distances.splice(index, 1)
                    return course;
                })
            }
        }
        setData({ ...temp });
    }

    const checkFormula = (e, v, name, name_id) => {
        const res = gameFormulaList.find(formula => {
            if (name_id === 'gt_id') {
                return formula.game_type_id === v.id && formula.play_mode_id === data.pm_id
            } else {
                return formula.game_type_id === data.gt_id && formula.play_mode_id === v.id
            }
        });
        let m = -1;
        let h = false;
        if (data.markings.length > 0) {
            h = data.markings.find(mark => mark.formula.handicap);
            if (h && h.type !== v.type) {
                h = true;
            } else {
                h = v.formula.handicap
            }
            m = data.markings.findIndex(mark => mark.type === v.type);
        } else {
            h = v.formula.handicap;
        }
        if (m < 0) {
            data.markings.push({
                id: v.id,
                type: v.type,
                formula: v.formula,
            })
        } else {
            data.markings[m].id = v.id;
            data.markings[m].formula = v.formula;
        }
        let mark = data.markings[m < 0 ? data.markings.length - 1 : m];
        let arr = mark.formula.results.map((r, index) => ({ ...r, id: `${mark.type}_${index}` }))
        if (name_id === 'pm_id') {
            setPlayModeMark({
                ...playModeMark,
                items: arr,
            })
        } else if (name_id === 'gt_id') {
            let tempHeaders = gameTypeMark.headers.map(item => {
                if (item.field === 'value') {
                    item.editable = mark.formula.editable;
                }
                return item
            })
            setGameTypeMark({
                ...gameTypeMark,
                headers: [...tempHeaders]
            })
            setGameTypeMark({
                ...gameTypeMark,
                items: arr,
            })
        }
        setData({
            ...data,
            [name]: v.name,
            [name_id]: v.id,
            markings: [...data.markings],
            // handicap_play_off: res ? res.handicap_play_off : 0,
            handicap_play_off: h,
        })

    }

    const ratingFormOnChange = (e, index) => {
        const { value, name } = e.target;
        let tempArr = data.ratings;
        tempArr[index][name] = value;
        setData({ ...data, ratings: tempArr })
    }

    const courseFormOnChange = (e, index) => {
        const { value, name } = e.target;
        let tempArr = data.courses;
        tempArr[index][name] = value;
        setData({ ...data, courses: [...tempArr] });
    }

    const distanceFormOnChange = (e, index, d_index) => {
        const { value, name } = e.target;
        let tempArr = data.courses;
        tempArr[index].course_distances[d_index][name] = value;
        setData({ ...data, courses: [...tempArr] });
    }

    const updateMarks = (updated, name) => {
        let temp = name === 'game_type' ? gameTypeMark : playModeMark;
        let index = temp.items.findIndex((item) => item.id === updated.id);
        temp.items[index].value = parseInt(updated.value);

        if (name === 'game_type') {
            setGameTypeMark({
                ...gameTypeMark,
                items: [...temp.items],
            })
        } else if (name === 'play_mode') {
            setPlayModeMark({
                ...playModeMark,
                items: [...temp.items],
            })
        }

        let m = data.markings.find(mark => {if (updated.id.indexOf(mark.type) > -1) return updated });
        if (m) {
            m.formula.results = [...temp.items];
        }
        setData({
            ...data,
            markings: [...data.markings] 
        })
        // if (m < 0) {
        //     data.markings.push({
        //         id: v.id,
        //         type: v.type,
        //         formula: v.formula,
        //     })
        // } else {
        //     data.markings[m].id = v.id;
        //     data.markings[m].formula = v.formula;
        // }
    }

    const updateMarksError = (e) => {
        console.log(e);
    }

    const checkCourseForm = async () => {
        let tempErr = error.courses;
        let isError = false;
        data.courses.map((course, index) => {
            if (!course.par) {
                isError = true;
                tempErr[index].par = translate(lang, "errorRequired");
            }
            if (!course.difficulty) {
                isError = true;
                tempErr[index].difficulty = translate(lang, "errorRequired");
            }
            let c_d = course.course_distances.find(distance => !distance.distance || distance.distance < 1 || distance.distance === '');
            if (c_d) {
                isError = true;
                tempErr[index].distance = translate(lang, "errorRequired");
            }
            return true;
        })
        return { tempErr, isError };
    }

    const submitForm = async () => {
        promptMessage(lang, 'info', translate(lang, 'validateForm'), {
            didOpen: () => {
                Swal.showLoading()
            },
            didClose: () => {
                Swal.hideLoading()
            }
        })
        let check = true;
        let error = { title: "", start_date: "", teeOffTime: "", game_type: "", play_mode: "", map_id: "", noOfHoles: "", ratings: "", courses: [], }
        if (!data.title) {
            check = false;
            error.title = translate(lang, "errorRequired");
        }
        if (!data.start_date) {
            check = false;
            error.start_date = translate(lang, "errorRequired");
        }
        if (!data.game_type) {
            check = false;
            error.game_type = translate(lang, "errorRequired");
        }
        if (!data.play_mode) {
            check = false;
            error.play_mode = translate(lang, "errorRequired");
        }
        if (!data.map_id || data.map_id < 1) {
            check = false;
            error.map_id = translate(lang, "errorRequired");
        }
        if (!data.noOfHoles || data.noOfHoles < 1) {
            check = false;
            error.noOfHoles = translate(lang, "errorRequired");
        }
        // check rating
        if (!data.ratings || data.ratings.length < 1) {
            check = false;
            error.ratings = translate(lang, "errorRequired");
        } else if (data.ratings.length > 0) {
            let ratings = data.ratings;
            let sameColor = findDuplicatesInArray(ratings, 'color');
            if (sameColor.find(item => item.value > 1)) {
                error.ratings = translate(lang, "noSameColorAllowed");
            }
        }

        // check course
        if ((!data.courses || data.courses.length < 1) && data.map_id > 0) {
            check = false;
            error.courses = translate(lang, "errorRequired");
        } else if (data.courses.length > 0) {
            let check_result = await checkCourseForm();
            if (check_result.isError) {
                check = false;
                error.courses = check_result.tempErr;
            }
        }
        setError({ ...error });
        if (check) {
            Swal.close();
            let res;
            if (type === 'create') {
                data.event_calculation = data.markings;
                res = await createTournament(data);
            } else {
                data.event_calculation = data.markings;
                res = await editTournament(data, id);
            }
            if (res.status === 'success') {
                Swal.fire({
                    title: translate(lang, 'success'),
                    html: `<p class="text-uppercase">${res.message}</p>`,
                    icon: 'success',
                    timer: 1500,
                }).then(() => history.push('/tournament/'))
                resetForm();
            } else {
                Swal.fire({
                    title: translate(lang, 'error'),
                    html: `<p class="text-uppercase">${res.message}</p>`,
                    icon: 'error',
                })
            }
        } else {
            promptMessage(lang, 'error', translate(lang, "formErrorMessage"));
        }
    }

    const resetForm = async () => {
        setData({
            title: "",
            game_type: "",
            play_mode: "",
            map_id: "",
            start_date: "",
            teeOffTime: "",
            noOfHoles: "",
            ratings: [],
            courses: [],
        })
    }

    return (
        <>
            <div className="card shadow col-12">
                <div className="card-header">
                    <h3 className="m-0">
                        {translate(lang, (type === "view" ? "tournamentDetails" : type === "edit" ? "editTournament" : "addTournament"))}
                    </h3>
                </div>
                <div className="card-body col-12 d-flex flex-column gap-3">
                    <div className="card shadow">
                        <div className="card-body d-flex flex-column gap-3">
                            <div className="d-flex flex-column flex-lg-row gap-4">
                                <div className="form-group col-12 col-lg-3">
                                    <label className="form-label">{translate(lang, 'title')}</label>
                                    <span className="ms-2 text-danger">*</span>
                                    <input type="text" value={data.title} onChange={(e) => { setData({ ...data, title: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                    {error.title === "" ? <></> : <p className="text-danger m-0">{error.title}</p>}
                                </div>
                                <div className="form-group col-12 col-lg-3">
                                    <label className="form-label">{translate(lang, 'playMode')}</label>
                                    <span className="ms-2 text-danger">*</span>
                                    <Autocomplete
                                        id="combo-box-play-mode"
                                        options={playModeList}
                                        value={data.play_mode ? playModeList.find(opt => opt.name === data.play_mode) : null}
                                        getOptionLabel={(options) => options.name}
                                        filterOptions={createFilterOptions({
                                            limit: 100,
                                            stringify: option => option.name,
                                        })}
                                        renderOption={(props, option) => {
                                            return <div {...props} key={option.id}>
                                                <p className="m-0">{option.name}</p>
                                            </div>
                                        }}
                                        renderInput={(params) => (
                                            <div ref={params.InputProps.ref}>
                                                <input type="text" {...params.inputProps} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                            </div>
                                        )}
                                        onChange={(e, v) => { checkFormula(e, v, 'play_mode', 'pm_id') }}
                                    />
                                    {error.play_mode === "" ? <></> : <p className="text-danger m-0">{error.play_mode}</p>}
                                </div>
                                <div className="form-group col-12 col-lg-3">
                                    <label className="form-label">{translate(lang, 'gameType')}</label>
                                    <span className="ms-2 text-danger">*</span>
                                    <Autocomplete
                                        id="combo-box-game-type"
                                        options={gameTypeList}
                                        value={data.game_type ? gameTypeList.find(opt => opt.name === data.game_type) : null}
                                        getOptionLabel={(options) => options.name}
                                        filterOptions={createFilterOptions({
                                            limit: 100,
                                            stringify: option => option.name,
                                        })}
                                        renderOption={(props, option) => {
                                            return <div {...props} key={option.id}>
                                                <p className="m-0">{option.name}</p>
                                            </div>
                                        }}
                                        renderInput={(params) => (
                                            <div ref={params.InputProps.ref}>
                                                <input type="text" {...params.inputProps} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                            </div>
                                        )}
                                        onChange={(e, v) => { checkFormula(e, v, 'game_type', 'gt_id') }}
                                    />
                                    {error.game_type === "" ? <></> : <p className="text-danger m-0">{error.game_type}</p>}
                                </div>
                                <div className="form-group col-12 col-lg-1 mb-4 mb-lg-0">
                                    <div>
                                        <label className="form-label">{translate(lang, 'handicap')}</label>
                                        <Checkbox checked={data.handicap_play_off} disabled={isHandicapDisabled} onChange={(e) => setData({ ...data, handicap_play_off: e.target.checked })} />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column flex-lg-row gap-4">
                                <div className="form-group col-12 col-lg-3">
                                    <label className="form-label">{translate(lang, 'startDate')}</label>
                                    <span className="ms-2 text-danger">*</span>
                                    <input type="date" value={data.start_date} onChange={(e) => { setData({ ...data, start_date: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                    {error.start_date === "" ? <></> : <p className="text-danger m-0">{error.start_date}</p>}
                                </div>
                                <div className="form-group col-12 col-lg-2">
                                    <label className="form-label">{translate(lang, 'teeOffTime')}</label>
                                    <input type="time" value={data.teeOffTime} onChange={(e) => { setData({ ...data, teeOffTime: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                </div>
                                <div className="form-group col-12 col-lg-3">
                                    <label className="form-label">{translate(lang, 'personInCharge')}</label>
                                    <span className="ms-2 text-danger">*</span>
                                    <Autocomplete
                                        id="combo-box-play-mode"
                                        options={userList}
                                        value={data.pic_id ? userList.find(opt => opt.id === data.pic_id) : null}
                                        getOptionLabel={(options) => options.name}
                                        filterOptions={createFilterOptions({
                                            limit: 100,
                                            stringify: option => option.name,
                                        })}
                                        renderOption={(props, option) => {
                                            return <div {...props} key={option.id}>
                                                <p className="m-0">{option.name}</p>
                                            </div>
                                        }}
                                        renderInput={(params) => (
                                            <div ref={params.InputProps.ref}>
                                                <input type="text" {...params.inputProps} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                            </div>
                                        )}
                                        onChange={(e, v) => setData({ ...data, pic_id: v.id })}
                                    />
                                    {error.pic_id === "" ? <></> : <p className="text-danger m-0">{error.pic_id}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card shadow">
                        <div className="card-header">
                            <Tabs value={currentTab} onChange={(event, value) => setCurrentTab(value)} aria-label="basic tabs example">
                                <Tab label={translate(lang, 'map')} />
                                <Tab label={translate(lang, 'course')} />
                                <Tab label={translate(lang, 'markings')} />
                            </Tabs>
                        </div>
                        <div className="card-body">
                            {<>
                                <div className={`d-flex flex-column gap-3 ${currentTab === 0 ? '' : 'd-none'}`}>
                                    <div className="d-flex flex-column flex-md-row gap-3">
                                        <div className="form-group py-2">
                                            <label className="form-label">{translate(lang, 'map')}</label>
                                            <span className="ms-2 text-danger">*</span>
                                            {type !== 'create' ?
                                                <input type="text" value={data.map_name} className="form-control" disabled={true} readOnly placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                                : <Autocomplete
                                                    id="combo-box-map"
                                                    options={mapList}
                                                    value={data.map_id ? mapList.find(opt => opt.id === data.map_id) : null}
                                                    getOptionLabel={(options) => options.name}
                                                    filterOptions={createFilterOptions({
                                                        limit: 100,
                                                        stringify: option => option.name,
                                                    })}
                                                    renderOption={(props, option) => {
                                                        return <div {...props} key={option.id}>
                                                            <p className="m-0">{option.name} ({translate(lang, 'courses')}: {option.total_courses})</p>
                                                        </div>
                                                    }}
                                                    renderInput={(params) => (
                                                        <div ref={params.InputProps.ref}>
                                                            <input type="text" {...params.inputProps} className="form-control" disabled={type === 'view' ? true : false} readOnly={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                                        </div>
                                                    )}
                                                    onChange={(e, v) => handleMapOptionsChanges(e, v)}
                                                />
                                            }
                                            {error.map_id === "" ? <></> : <p className="text-danger m-0">{error.map_id}</p>}
                                        </div>
                                        <div className="form-group py-2">
                                            <label className="form-label">{translate(lang, 'numberOfHoles')}</label>
                                            <span className="ms-2 text-danger">*</span>
                                            <input type="number" name="noOfHoles" value={data.noOfHoles} onChange={(e) => mapFormOnChange(e)} onWheel={(e) => e.target.blur()} readOnly={type === 'view' ? true : false} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                            {error.noOfHoles === "" ? <></> : <p className="text-danger m-0">{error.noOfHoles}</p>}
                                        </div>
                                    </div>
                                    <label className="form-label">
                                        {translate(lang, 'ratings')}<span className="ms-2 text-danger">*</span>
                                        {data.map_id > 0 ?
                                            <span><button className="btn btn-sm btn-primary ms-3" onClick={() => handleRatingForm('add')}>{translate(lang, 'addRating')}</button></span>
                                            : <></>
                                        }
                                        {error.ratings === "" ? <></> : <p className="text-danger m-0">{error.ratings}</p>}
                                    </label>
                                    <div className="d-flex flex-column flex-md-row gap-2 overflow-auto px-3">
                                        {data.ratings.length > 0 ? data.ratings.map((rating, index) => {
                                            return (
                                                <div className="card shadow col-12 col-md-6 col-xl-4" key={index}>
                                                    <div className="card-body d-flex flex-column gap-2">
                                                        <div className="d-flex flex-column flex-md-row gap-3">
                                                            <div className="form-group py-2 col-12 col-md-3">
                                                                <label className="form-label">{translate(lang, 'color')}</label>
                                                                <input type="color" name="color" value={rating.color} onChange={(e) => ratingFormOnChange(e, index)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                                            </div>
                                                            <div className="form-group py-2 col-12 col-md">
                                                                <label className="form-label">{translate(lang, 'description')}</label>
                                                                <input type="text" name="description" value={rating.description} onChange={(e) => ratingFormOnChange(e, index)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-column flex-md-row gap-3">
                                                            <div className="form-group py-2 col-12 col-md">
                                                                <label className="form-label">{translate(lang, 'slopeRating')}</label>
                                                                <input type="number" name="slopeRating" onWheel={(e) => e.target.blur()} value={rating.slopeRating} onChange={(e) => ratingFormOnChange(e, index)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                                            </div>
                                                            <div className="form-group py-2 col-12 col-md">
                                                                <label className="form-label">{translate(lang, 'courseRating')}</label>
                                                                <input type="number" name="courseRating" onWheel={(e) => e.target.blur()} value={rating.courseRating} onChange={(e) => ratingFormOnChange(e, index)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                                            </div>
                                                        </div>
                                                        {data.ratings.length > 1 ?
                                                            <button className="btn btn-danger" onClick={() => handleRatingForm('remove', index)}>{translate(props.lang, 'delete')}</button>
                                                            : <></>
                                                        }
                                                    </div>
                                                </div>)
                                        }) : <><p>{translate(lang, 'selectMapFirst')}</p></>}
                                    </div>
                                </div>
                                <div className={`d-flex flex-column ${currentTab === 1 ? '' : 'd-none'}`}>
                                    <div>
                                        {data.courses.length ? data.courses.map((course, index) => {
                                            return (
                                                <Accordion key={index + "_course"} expanded={currentAccordion === index} onChange={() => setCurrentAccordion(index)}>
                                                    <AccordionSummary
                                                        expandIcon={<GridExpandMoreIcon />}
                                                        style={{
                                                            backgroundColor: '#f0f0f0'
                                                        }}
                                                    >
                                                        <h5 className="m-0 text-center gap-4 d-flex ">
                                                            <span>{translate(lang, "holeNo")} : {course.hole_no}</span>
                                                            <span className="d-none d-lg-block">{translate(lang, "par")} : {course.par}</span>
                                                            <span className="d-none d-lg-block">{translate(lang, "difficultyIndex")} : {course.difficulty}</span>
                                                        </h5>
                                                        {/* <h5 className="m-0 text-center"><span>{translate(lang, "holeNo")} : {course.hole_no}</span></h5> */}
                                                        {error.courses[index] && (error.courses[index].par !== '' || error.courses[index].difficulty !== '' || error.courses[index].distance !== '') ?
                                                            <span className="text-danger ms-3 h5 m-0"><FontAwesomeIcon icon={faTriangleExclamation}></FontAwesomeIcon></span>
                                                            : <></>
                                                        }
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div className="d-flex flex-column gap-2">
                                                            {/* <p>{JSON.stringify(error.courses)}</p> */}
                                                            <div className="d-flex flex-column flex-md-row gap-3">
                                                                <div className="form-group py-2">
                                                                    <label className="form-label">{translate(lang, 'holeNo')}</label>
                                                                    <input type="number" value={course.hole_no} className="form-control" onWheel={(e) => e.target.blur()} disabled={true} readOnly={true} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                                                </div>
                                                                <div className="form-group py-2">
                                                                    <label className="form-label">{translate(lang, 'par')}</label>
                                                                    <span className="ms-2 text-danger">*</span>
                                                                    <input type="number" name="par" onChange={(e) => courseFormOnChange(e, index)} value={course.par} className="form-control" onWheel={(e) => e.target.blur()} disabled={type === 'view' ? true : false} readOnly={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                                                    {error.courses === "" && error.courses.length < 1 && typeof error.courses !== 'string' ? <></> :
                                                                        <p className="text-danger m-0">{error.courses[index]?.par}</p>
                                                                    }
                                                                </div>
                                                                <div className="form-group py-2">
                                                                    <label className="form-label">{translate(lang, 'difficultyIndex')}</label>
                                                                    <span className="ms-2 text-danger">*</span>
                                                                    <input type="number" name="difficulty" onChange={(e) => courseFormOnChange(e, index)} value={course.difficulty} className="form-control" onWheel={(e) => e.target.blur()} disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                                                    {error.courses === "" && error.courses.length < 1 && typeof error.courses !== 'string' ? <></> :
                                                                        <p className="text-danger m-0">{error.courses[index]?.difficulty}</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="">
                                                                <label className="form-label">{translate(lang, 'distance')}</label>
                                                                <span className="ms-2 text-danger">*</span>
                                                                {error.courses === "" && error.courses.length < 1 && typeof error.courses !== 'string' ? <></> :
                                                                    <p className="text-danger m-0">{error.courses[index]?.distance}</p>
                                                                }
                                                                <div className="d-flex flex-column flex-md-row gap-2 px-2 px-md-3 overflow-auto">
                                                                    {course.course_distances ? course.course_distances.map((distance, d_index) => {
                                                                        return <div className="card shadow" key={d_index}>
                                                                            <div className="card-body d-flex flex-column gap-2">
                                                                                <label className="form-label">{translate(lang, 'color')}: <b className="box-color" style={{ backgroundColor: distance.color }}><span className="box-color-text">{distance.color}</span></b></label>
                                                                                {/* <label className="form-label">{translate(lang, 'color')}: <span className="box-color" style={{backgroundColor: distance.color}}></span> <b>{distance.color}</b></label> */}
                                                                                <label className="form-label">{translate(lang, 'description')}: <b className="text-capitalize">{data.ratings.find(rate => rate.color === distance.color) ? data.ratings.find(rate => rate.color === distance.color).description : ""}</b></label>
                                                                                <input type="number" name="distance" onChange={(e) => distanceFormOnChange(e, index, d_index)} value={distance.distance} className="form-control" onWheel={(e) => e.target.blur()} disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                                                            </div>
                                                                        </div>
                                                                    }) : <><p>{translate(lang, 'addRatingForDistance')}</p></>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>
                                            )
                                        })
                                            : (typeof error.courses === 'string' && error.courses !== "") ? <><p>{error.courses}</p></>
                                                : <><p>{translate(lang, 'selectMapFirst')}</p></>}
                                    </div>
                                </div>
                                <div className={`d-flex flex-column flex-lg-row gap-2 ${currentTab === 2 ? '' : 'd-none'}`}>
                                    <div className="col-12 col-lg-6 d-flex flex-column gap-2">
                                        <h5>{translate(lang, 'playMode')}</h5>
                                        {playModeMark.items.length > 0 ? <>
                                            <DataGrid
                                                getRowId={(row, index) => row.id}
                                                rows={[...playModeMark.items]}
                                                columns={[...playModeMark.headers]}
                                                processRowUpdate={(updatedRow, originalRow) => {
                                                    updateMarks(updatedRow, 'play_mode')
                                                    return updatedRow
                                                }}
                                                hideFooter={true}
                                                onProcessRowUpdateError={updateMarksError}
                                                isCellEditable={(params) => !['condition'].includes(params.id) ? true : false}
                                            />
                                        </> : <><p>{translate(lang, 'selectGameTypeOrPlayModeFirst')}</p></>}
                                    </div>
                                    <div className="col-12 col-lg-6 d-flex flex-column gap-2">
                                        <h5>{translate(lang, 'gameType')}</h5>
                                        {gameTypeMark.items.length > 0 ? <>
                                            <DataGrid
                                                getRowId={(row, index) => row.id}
                                                rows={[...gameTypeMark.items]}
                                                columns={[...gameTypeMark.headers]}
                                                processRowUpdate={(updatedRow, originalRow) => {
                                                    updateMarks(updatedRow, 'game_type')
                                                    return updatedRow
                                                }}
                                                hideFooter={true}
                                                onProcessRowUpdateError={updateMarksError}
                                                isCellEditable={(params) => !['condition'].includes(params.id) ? true : false}
                                            />
                                        </> : <><p>{translate(lang, 'selectGameTypeOrPlayModeFirst')}</p></>}
                                    </div>
                                </div>
                            </>}
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <div className="d-flex flex-row">
                        <button type="button" hidden={type === 'view' ? true : false} className="btn btn-primary btn-sm" onClick={() => submitForm()}>{translate(lang, `${type === 'edit' ? 'save' : type}`)}</button>
                        <button type="button" className="btn btn-dark mx-2 btn-sm" onClick={() => history.push('/tournament')}>{translate(lang, "back")}</button>
                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(TournamentForm);