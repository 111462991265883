import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { getTournamentList, removeTournament, updateTournamentStatus } from "../../../services/ApiService";
import { promptMessage } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import MuiTable from "../../components/MuiTable";
import { faFileCircleCheck, faHand, faMarker, faPeopleGroup, faPlay, faStop, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TournamentList = (props) => {
    const lang = props.lang;
    const history = useHistory();
    const [searchText, setSearchText] = useState('');
    const [list, setList] = useState({
        items: [],
        headers: [
            { field: 'title', headerName: translate(lang, 'title'), flex: 1, minWidth: 200, restricted: [], },
            {
                field: 'user', headerName: translate(lang, 'personInCharge'), flex: 1, minWidth: 150, restricted: [], valueGetter: (params) => {
                    return params.row.user.name;
                }
            },
            { field: 'game_type', headerName: translate(lang, 'gameType'), flex: 0, minWidth: 150, restricted: [], },
            { field: 'play_mode', headerName: translate(lang, 'playMode'), flex: 0, minWidth: 150, restricted: [], },
            {
                field: 'game_status', headerName: translate(lang, 'tournamentStatus'), flex: 0, minWidth: 225, restricted: [], valueGetter: (params) => {
                    return translate(lang, params.row.game_status);
                }
            },
            { field: 'start_date', headerName: translate(lang, 'startDate'), flex: 0, minWidth: 100, restricted: [], },
            {
                field: 'players', headerName: translate(lang, 'players'), flex: 0, minWidth: 100, restricted: [], valueGetter: (params) => {
                    return params.row.tournament_players.length
                },
                headerAlign: 'center',
                align: 'center',
            },
            {
                field: 'tee', headerName: translate(lang, 'teeOffTime'), flex: 0, minWidth: 100, restricted: [], valueGetter: (params) => {
                    return params.row.tee ?? "-";
                }
            },
            {
                field: 'actions_admin', type: 'actions', headerName: translate(lang, 'action'), flex: 1, minWidth: 300, restricted: ['player'], 
                headerAlign: 'center',
                align: 'start',
                getActions: (params) => {
                    let arr = [
                        <Link to={`/tournament/${params.row.title}/${params.id}/player`} title={translate(lang, "players")} className="btn btn-sm btn-info cursor-pointer"><FontAwesomeIcon icon={faPeopleGroup}></FontAwesomeIcon></Link>,
                        <button onClick={() => startEvent(params.id, params.row)} title={translate(lang, "start")} className="btn btn-sm btn-primary cursor-pointer"><FontAwesomeIcon icon={faPlay}></FontAwesomeIcon></button>,
                        <Link to={`/tournament/edit/${params.id}`} title={translate(lang, "save")} className="btn btn-sm btn-warning cursor-pointer" > <FontAwesomeIcon icon={faMarker}></FontAwesomeIcon></Link>,
                        <button onClick={() => removeItem(params.id)} title={translate(lang, "delete")} className="btn btn-sm btn-danger cursor-pointer"><FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon></button>,
                    ];
                    if (!['master', 'admin'].includes(props.auth.auth.data.role)) {
                        arr = [
                            <Link to={`/tournament/${params.row.title}/${params.id}/player`} title={translate(lang, "players")} className="btn btn-sm btn-info cursor-pointer"><FontAwesomeIcon icon={faPeopleGroup}></FontAwesomeIcon></Link>,
                            <button onClick={() => startEvent(params.id, params.row)} title={translate(lang, "start")} className="btn btn-sm btn-primary cursor-pointer"><FontAwesomeIcon icon={faPlay}></FontAwesomeIcon></button>,
                        ]
                    }
                    if (['tournament_cancel', 'tournament_completed'].includes(params.row.game_status)) {
                        arr = [
                            <Link to={`/tournament/${params.row.title}/${params.id}/player`} title={translate(lang, "players")} className="btn btn-sm btn-info cursor-pointer"><FontAwesomeIcon icon={faPeopleGroup}></FontAwesomeIcon></Link>,
                            <Link to={`/tournament/${params.row.title}/${params.id}/handicap`} title={translate(lang, "handicapResult")} className={`btn btn-sm btn-secondary cursor-pointer ${params.row.handicap_play_off ? "d-block" : "d-none"}`}><FontAwesomeIcon icon={faHand}></FontAwesomeIcon></Link>,
                            <Link to={`/tournament/${params.row.title}/${params.id}/marking`} title={translate(lang, "tournamentResult")} className="btn btn-sm btn-dribbble cursor-pointer"><FontAwesomeIcon icon={faFileCircleCheck}></FontAwesomeIcon></Link>,
                        ]
                    } else if (params.row.game_status !== 'incoming') {
                        arr = [
                            <Link to={`/tournament/${params.row.title}/${params.id}/player`} title={translate(lang, "players")} className="btn btn-sm btn-info cursor-pointer"><FontAwesomeIcon icon={faPeopleGroup}></FontAwesomeIcon></Link>,
                            <button onClick={() => stopEvent(params.id)} title={translate(lang, "stop")} className="btn btn-sm btn-danger cursor-pointer"><FontAwesomeIcon icon={faStop}></FontAwesomeIcon></button>,
                            <Link to={`/tournament/${params.row.title}/${params.id}/handicap`} title={translate(lang, "handicapResult")} className={`btn btn-sm btn-secondary cursor-pointer ${params.row.handicap_play_off ? "d-block" : "d-none"}`}><FontAwesomeIcon icon={faHand}></FontAwesomeIcon></Link>,
                            <Link to={`/tournament/${params.row.title}/${params.id}/marking`} title={translate(lang, "tournamentResult")} className="btn btn-sm btn-dribbble cursor-pointer"><FontAwesomeIcon icon={faFileCircleCheck}></FontAwesomeIcon></Link>,
                        ]
                    }
                    return arr;
                }
            },
            // {
            //     field: 'actions_agent', type: 'actions', headerName: translate(lang, 'action'), flex: 2, minWidth: 100, restricted: ['admin', 'master'], getActions: (params) => {
            //         let action = '';
            //         return [
            //             <button onClick={() => startEvent(params.id, params.row)} title={translate(lang, "start")} className="btn btn-sm btn-primary cursor-pointer"><FontAwesomeIcon icon={faPlay}></FontAwesomeIcon></button>,
            //             <Link to={`/tournament/${params.row.title}/${params.id}/player`} title={translate(lang, "players")} className="btn btn-sm btn-info cursor-pointer"><FontAwesomeIcon icon={faPeopleGroup}></FontAwesomeIcon></Link>,
            //             <Link to={`/tournament/${params.row.title}/${params.id}/handicap`} title={translate(lang, "handicapResult")} className={`btn btn-sm btn-secondary cursor-pointer ${params.row.handicap_play_off ? "d-block" : "d-none"}`}><FontAwesomeIcon icon={faHand}></FontAwesomeIcon></Link>,
            //             <Link to={`/tournament/${params.row.title}/${params.id}/marking`} title={translate(lang, "tournamentResult")} className="btn btn-sm btn-dribbble cursor-pointer"><FontAwesomeIcon icon={faFileCircleCheck}></FontAwesomeIcon></Link>,
            //         ]
            //     }
            // },
        ],
    });
    const [exportData, setExportData] = useState({
        name: "tournament_list_report",
        excelData: [],
        hasCustom: false,
        customRows: [{
            customData: [],
            origin: -1, // last row or specified location like 'A1'
        }],
    });

    useEffect(() => {
        getList()
    }, [searchText])

    async function getList() {
        let query = {};
        if (searchText !== '') {
            query = {
                title: searchText,
                gameType: searchText,
                playMode: searchText,
                gameStatus: searchText,
                startDate: searchText,
                teeOffTime: searchText,
                isSearch: true,
            }
        }
        let res = await getTournamentList(query);
        if (res?.status === 'success') {
            setList({
                ...list,
                items: res.data,
            })
            setExportData({
                ...exportData,
                excelData: res.data,
            });
        } else {
        }
    }

    function onTextSearched(value) {
        setSearchText(value);
    }

    function onActionTaken(value) {
        if (value === 'remove') {
            removeItem()
        } else if (value === 'edit') {
            history.push('');
        }
    }

    function startEvent(id, item) {
        Swal.fire({
            title: translate(props.lang, "startEventTitle"),
            text: translate(props.lang, "startEventMessage"),
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (item.tournament_players.length < 1) {
                    Swal.fire({
                        title: translate(props.lang, "noPlayerInEventTitle"),
                        text: translate(props.lang, "noPlayerInEventMessage"),
                        showDenyButton: true,
                        showCancelButton: false,
                        confirmButtonText: 'Yes',
                        denyButtonText: 'No',
                        customClass: {
                            actions: 'my-actions',
                            cancelButton: 'order-1 right-gap',
                            confirmButton: 'order-2',
                            denyButton: 'order-3',
                        }
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            let res = await updateTournamentStatus(id, 'start')
                            if (res.status === 'success') {
                                promptMessage(lang, 'success', res.message)
                                getList();
                            } else {
                                promptMessage(lang, 'error', res.message ?? "error")
                                getList();
                            }
                        }
                    })
                } else {
                    let res = await updateTournamentStatus(id, 'start')
                    if (res.status === 'success') {
                        promptMessage(lang, 'success', res.message)
                        getList();
                    } else {
                        promptMessage(lang, 'error', res.message ?? "error")
                        getList();
                    }
                }
            }
        })
    }

    function stopEvent(id) {
        Swal.fire({
            title: translate(props.lang, "stopEventTitle"),
            text: translate(props.lang, "stopEventMessage"),
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await updateTournamentStatus(id, 'stop')
                if (res.status === 'success') {
                    promptMessage(lang, 'success', res.message)
                    getList();
                } else {
                    promptMessage(lang, 'error', res.message ?? "error")
                    getList();
                }
            }
        })
    }

    // function startTournament(id) {
    //     Swal.fire({
    //         title: translate(props.lang, "deleteConfirmation"),
    //         text: translate(props.lang, "deleteMessage"),
    //         showDenyButton: true,
    //         showCancelButton: false,
    //         confirmButtonText: 'Yes',
    //         denyButtonText: 'No',
    //         customClass: {
    //             actions: 'my-actions',
    //             cancelButton: 'order-1 right-gap',
    //             confirmButton: 'order-2',
    //             denyButton: 'order-3',
    //         }
    //     }).then(async (result) => {
    //         if (result.isConfirmed) {
    //             let res = await removeTournament(id)
    //             if (res.status === 'success') {
    //                 promptMessage(lang, 'success', res.message)
    //                 getList();
    //             } else {
    //                 promptMessage(lang, 'error', res.message ?? "error")
    //                 getList();
    //             }
    //         }
    //     })
    // }

    function removeItem(id) {
        Swal.fire({
            title: translate(props.lang, "deleteConfirmation"),
            text: translate(props.lang, "deleteMessage"),
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await removeTournament(id)
                if (res.status === 'success') {
                    promptMessage(lang, 'success', res.message)
                    getList();
                } else {
                    promptMessage(lang, 'error', res.message ?? "error")
                    getList();
                }
            }
        })
    }

    return (
        <>
            <div className="card shadow">
                <div className="card-header">
                    <div>
                        <Link to="/tournament/create" className="btn btn-primary btn-sm">{translate(lang, 'addTournament')}</Link>
                    </div>
                </div>
                <div className="card-body">
                    <MuiTable list={{ ...list }}
                        role={props.auth.auth.data.role}
                        lang={props.lang} exportData={exportData}
                        onTextSearched={(e) => onTextSearched(e)}
                        onActionTaken={(e) => onActionTaken(e)}
                        options={{ showFooter: true }}
                    ></MuiTable>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(TournamentList);