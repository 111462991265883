const en = {
    // common
    error: "Error",
    success: "Success",
    loading: "Loading",
    noResultFound: "No Result Found",
    goToPage: "Go To Page",
    page: "Page",
    show: "Show",
    searchHere: "Search here",
    searchTable: "Search Table",
    exportExcel: "Export Excel",
    exportPDF: "Export PDF",
    exportTxt: "Export TXT",
    index: "No.",
    name: "Name",
    fullName: "Full Name",
    age: "Age",
    identityCard: "Identity Card",
    birthDate: "Birth Date",
    phoneCode: "Phone Code",
    phone: "Phone",
    email: "Email",
    gender: "Gender",
    memberId: "Member ID",
    action: "Action",
    relationship: "Relationship",
    male: "Male",
    female: "Female",
    status: "Status",
    remark: "Remark",
    list: "List",
    type: "Type",
    description: "Description",
    icon: "Icon",
    placeholderEnterHere: "Enter here",
    placeholderChoose: "Choose one option",
    chooseOne: "Choose One",
    chooseMultiple: "Choose Multiple",
    fillIn: "Fill In",
    username: "Username",
    password: "Password",
    confirmPassword: "Confirm Password",

    // form error message
    errorRequired: "This is required",
    internalServerError: "Internal server error, please contact Admin",
    pinNotMatch: "Pin not match",
    noItemFound: "No item found",
    noQuestionFound: "No Question Found.",
    noAnswerOptionFound: "No Answer Option Found.",
    noSameColorAllowed: "Same color is not allowed in ratings, must be unique",
    distanceCannotEmpty: "Distance of course cannot be empty",

    // confirmation box message
    deleteConfirmation: "Delete Confirmation Box",
    deleteMessage: "Are you sure to remove this? Action cannot be undone.",
    deleteLastCourseConfirmation: "Delete Most Latest Course Confirmation Box",
    deleteLastCourseMessage: "Are you sure to remove this? Action cannot be undone.",
    updateStatusConfirmation: "Update this item status",
    updateStatusMessage: "Are you sure to update this status? Action might affect some data view.",


    // Message box return message

    // button
    create: "Create",
    edit: "Edit",
    delete: "Delete",
    back: "Back",
    update: "Update",
    start: "Start",
    pause: "Pause",
    stop: "Stop",
    save: "Save",
    createAnother: "Create Another",

    // language
    en: "EN",
    cn: "CN",
    bm: "BM",
    english: "English",
    chinese: "Chinese",
    melayu: "Bahasa Melayu",

    //options
    url: "URL",
    image: "Image",

    // project start
    // nav
    dashboard: "Dashboard",
    member: "Member",
    user: "User",
    tournament: "Tournament",
    settings: "Settings",
    profile: "Profile",
    
    addUser: "Add User",
    editUser: "Edit User",
    userDetails: "User Details",
    role: "Role",
    master: "Master",
    admin: "Admin",
    agent: "Agent",

    map: "Map",
    course: "Course",
    addMap: "Add Map",
    editMap: "Edit Map",
    mapDetails: "Map Details",
    viewCourse: "View Course",
    addCourse: "Add Course",
    editCourse: "Edit Course",
    deleteLastCourse: "Delete Last Course",
    courseDetails: "Course Details",
    holeNo: "Hole No.",
    par: "Par",
    distance: "Distance",
    color: "Color",
    slopeRating: "Slope Rating",
    courseRating: "Course Rating",
    addRating: "Add Rating",
    ratings: "Ratings",
    difficultyIndex: "Index",
    numberOfHoles: "Number of Holes",
    numberOfCourses: "Number of Courses",
    startDate: "Start Date",
    location: "Location",
    gamePlay: "Game/Play",
    gamePlaySettings: "Game/Play settings",
    gamePlayDetails: "Game/Play details",
    createGamePlay: "Create Game/Play",
    editGamePlay: "Edit Game/Play",
    gameType: "Game Type",
    addGameType: "Add Game Type",
    playMode: "Play Mode",
    addPlayMode: "Add Play Mode",
    addTournament: "Add Tournament",
    editTournament: "Edit Tournament",
    tournamentDetails: "Tournament Details",
    active: "Active",
    inactive: "Inactive",
    title: "Title",
    teeOffTime: "Tee-off time",
    selectMapFirst: "Please select a map first.",
    addRatingForDistance: "Please add a rating before declaring distance.",
    startHandicap: "Start Handicap",
    stopHandicap: "Stop Handicap",
    endHandicap: "End Handicap",
    handicapResult: "Handicap result",
    startTournament: "Start Tournament",
    stopTournament: "Stop Tournament",
    endTournament: "End Tournament",
    tournamentResult: "Tournament result",
    players: "Players",
    player: "Player",
    addPlayer: "Add Player",
    editPlayer: "Edit Player",
    playerDetails: "Player Details",
    removePlayer: "Remove Player",
    addMember: "Add Member",
    editMember: "Edit Member",
    memberDetails: "Member Details",
    handicap: "Handicap",
    teeColor: "Tee-Color",
    duplicateIndex: "Duplicate Index Founded",
    personInCharge: "Person In Charge",
    noPlayerFound: "No player found",
    tournamentStatus: "Tournament Status",
    incoming: "Incoming",
    handicap_start: "Handicap Started",
    handicap_completed: "Handicap Completed",
    waitingHandicapEnd: "Waiting for handicap event to completed...",
    tournament_start: "Tournament Start",
    tournament_completed: "Tournament Completed",
    tournament_cancel: "Tournament Cancel",
    markings: "Markings",
    formula: "Formula",
    markingsAndFormula: "Markings And Formula",
    selectGameTypeOrPlayModeFirst: "Please select a play mode or game type to view their formula",

    warning: "Warning",
    info: "Info",
    validateForm: "Validating Form...",
    formErrorMessage: "Form input error, please recheck!",
    nothingToShowHere: "Nothing To Show Here",
    nothingToDelete: "Nothing To Delete",
    noPlayerInEventTitle: "No player found in event",
    noPlayerInEventMessage: "There is no player found in this event, are you sure to start ?",
    startEventTitle: "Start this event ?",
    startEventMessage: "Are you sure to start this event ?",
    stopEventTitle: "Stop this event ?",
    stopEventMessage: "Are you sure to stop this event ?",
    noHandicapPlayOff: "No Handicap event in this tournament",
    startHandicapTitle: "Start handicap play off",
    startHandicapMessage: "Are you sure to start this event?",
    startTournamentTitle: "Start tournament",
    startTournamentMessage: "Are you sure to start this tournament?",
    endHandicapTitle: "Handicap Event Ended ?",
    endHandicapMessage: "Are you sure this handicap event had ended and all results are correct, after confirm event ended, data cannot be reverted or change anymore!",
    endTournamentTitle: "Event Ended ?",
    endTournamentMessage: "Are you sure this event had ended and all results are correct, after confirm event ended, data cannot be reverted or change anymore!",

    total: "Total",
    totalStroke: "Total Stroke",
    totalScore: "Total Score",
    totalHandicap: "Total Handicap",
    point: "Point",
    stroke: "Stroke",
    markingName: "Name",
    noMarkingFound: "No Marking Found",
    condition: "Condition",
    score: "Score",
    result: "Result",
    finalResult: "Final Result",
    addNegative: "Add Negative",
    addPositive: "Add Positive",

    clickToEditTable: "Double click column to edit",
    note: "Note",
}
export default en;