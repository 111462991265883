import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { getTournamentById, getTournamentPlayerList, removeTournamentPlayer } from "../../../services/ApiService";
import { promptMessage } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import MuiTable from "../../components/MuiTable";
import { faMarker, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PlayerList = (props) => {
    const lang = props.lang;
    const { tId, tName } = useParams();
    const history = useHistory();
    const [searchText, setSearchText] = useState('');
    const [tournament, setTournament] = useState({});
    const [list, setList] = useState({
        items: [],
        headers: [
            { field: 'name', headerName: translate(lang, 'name'), flex: 0, minWidth: 200, restricted: [], },
            { field: 'age', headerName: translate(lang, 'age'), flex: 0, minWidth: 100, restricted: [], },
            { field: 'gender', headerName: translate(lang, 'gender'), flex: 0, minWidth: 100, restricted: [], },
            {
                field: 'tee_color', headerName: translate(lang, 'teeColor'), flex: 0, minWidth: 150, restricted: [], renderCell: (params) => {
                    return <div className="d-flex flex-row gap-2">
                        <span className="p-2 box-color border-1" style={{ backgroundColor: params.row.tee_color }}></span>
                        <span className="">{params.row.tee_color}</span>
                    </div>
                },
            },
            { field: 'email', headerName: translate(lang, 'email'), flex: 0, minWidth: 250, restricted: [], },
            { field: 'phone', headerName: translate(lang, 'phone'), flex: 0, minWidth: 150, restricted: [], },
            { field: 'date_of_birth', headerName: translate(lang, 'birthDate'), flex: 0, minWidth: 150, restricted: [], },
            { field: 'member_id', headerName: translate(lang, 'memberId'), flex: 0, minWidth: 150, restricted: [], },
            {
                field: 'actions', type: 'actions', headerName: translate(lang, 'action'), flex: 1, minWidth: 200, restricted: ['agent', 'player'], getActions: (params) => [
                    <Link to={`/tournament/${tName}/${tId}/player/edit/${params.id}`} title={translate(lang, "save")} className=" btn btn-sm btn-warning cursor-pointer"><FontAwesomeIcon icon={faMarker}></FontAwesomeIcon></Link>,
                    <button onClick={() => removeItem(params.id)} title={translate(lang, "delete")} className="btn btn-sm btn-danger cursor-pointer"><FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon></button>,
                ]
            },
        ],
    });
    const [exportData, setExportData] = useState({
        name: "tournament_player_list_report",
        excelData: [],
        hasCustom: false,
        customRows: [{
            customData: [],
            origin: -1, // last row or specified location like 'A1'
        }],
    });

    useEffect(() => {
        if (!tId || !tName) {
            history.push('/tournament');
            return;
        }
        getList()
        getTournament();
    }, [searchText])

    async function getTournament() {
        const res = await getTournamentById(tId)
        if (res.status === 'success') {
            setTournament({
                ...res.data
            })
        } else {

        }
    }

    async function getList() {
        let query = {};
        if (searchText !== '') {
            query = {
                name: searchText,
                age: searchText,
                gender: searchText,
                tee_color: searchText,
                email: searchText,
                phone: searchText,
                date_of_birth: searchText,
                member_id: searchText,
                isSearch: true,
            }
        }
        let res = await getTournamentPlayerList(tId, query);
        if (res?.status === 'success') {
            setList({
                ...list,
                items: res.data,
            })
            setExportData({
                ...exportData,
                excelData: res.data,
            });
        } else {
        }
    }

    function onTextSearched(value) {
        setSearchText(value);
    }

    function onActionTaken(value) {
        if (value === 'remove') {
            removeItem()
        } else if (value === 'edit') {
            history.push('');
        }
    }

    function removeItem(id) {
        Swal.fire({
            title: translate(props.lang, "deleteConfirmation"),
            text: translate(props.lang, "deleteMessage"),
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await removeTournamentPlayer(tId, id)
                if (res.status === 'success') {
                    promptMessage(lang, 'success', res.message)
                    getList();
                } else {
                    promptMessage(lang, 'error', res.message ?? "error")
                    getList();
                }
            }
        })
    }

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <div>
                        <h4>{translate(lang, 'tournament')}: {tName}</h4>
                    </div>
                    <div>
                        {tournament.game_status !== 'incoming' ? <></> :
                            <Link to={`/tournament/${tName}/${tId}/player/create`} className="btn btn-primary btn-sm">{translate(lang, 'addPlayer')}</Link>
                        }
                    </div>
                </div>
                <div className="card-body">
                    <div className="d-flex flex-column col-12 gap-3" style={{ minHeight: '10rem' }}>
                        <div className=""><Link to="/tournament" className="btn btn-sm btn-dark">{translate(lang, 'back')}</Link></div>
                        <div className="col-12" style={{ height: `${list.items.length > 0 ? 'auto' : '15rem'}` }}>
                            <MuiTable list={{ ...list }} role={props.auth.auth.data.role} lang={props.lang} exportData={exportData} onTextSearched={(e) => onTextSearched(e)} onActionTaken={(e) => onActionTaken(e)}></MuiTable>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(PlayerList);