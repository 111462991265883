import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { getUserList, removeUser } from "../../../services/ApiService";
import { promptMessage } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import MuiTable from "../../components/MuiTable";
import { faMarker, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const UserList = (props) => {
    const lang = props.lang;
    const history = useHistory();
    const [searchText, setSearchText] = useState('');
    const [list, setList] = useState({
        items: [],
        headers: [
            { field: 'name', headerName: translate(lang, 'name'), restricted: [], flex: 1, minWidth: 200, },
            { field: 'username', headerName: translate(lang, 'username'), restricted: [], flex: 1, minWidth: 200, },
            { field: 'role', headerName: translate(lang, 'role'), restricted: ['agent', 'player'], flex: 1, minWidth: 150, },
            { field: 'gender', headerName: translate(lang, 'gender'), restricted: [], flex: 1, minWidth: 150, },
            { field: 'phone', headerName: translate(lang, 'phone'), restricted: [], flex: 1, minWidth: 150, },
            { field: 'email', headerName: translate(lang, 'email'), restricted: [], flex: 1, minWidth: 200, },
            {
                field: 'actions', type: 'actions', headerName: translate(lang, 'action'), restricted: ['agent', 'player'], flex: 1, minWidth: 100, getActions: (params) => [
                    <Link to={`/user/edit/${params.id}`} title={translate(lang, "save")} className=" btn btn-sm btn-warning cursor-pointer"><FontAwesomeIcon icon={faMarker}></FontAwesomeIcon></Link>,
                    <button onClick={() => removeItem(params.id)} title={translate(lang, "delete")} className="btn btn-sm btn-danger cursor-pointer"><FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon></button>
                ]
            },
        ],
    });
    const [exportData, setExportData] = useState({
        name: "user_list_report",
        excelData: [],
        hasCustom: false,
        customRows: [{
            customData: [],
            origin: -1, // last row or specified location like 'A1'
        }],
    });

    useEffect(() => {
        getList()
    }, [searchText])

    async function getList() {
        let query = {};
        if (searchText !== '') {
            query = {
                username: searchText,
                name: searchText,
                phone: searchText,
                email: searchText,
                role: searchText,
                isSearch: true,
            }
        }
        let res = await getUserList(query);
        if (res?.status === 'success') {
            setList({
                ...list,
                items: res.data,
            })
            setExportData({
                ...exportData,
                excelData: res.data,
            });
        } else {
        }
    }

    function onTextSearched(value) {
        setSearchText(value);
    }

    function onActionTaken(value) {
        if (value === 'remove') {
            removeItem()
        } else if (value === 'edit') {
            history.push('');
        }
    }

    function removeItem(id) {
        Swal.fire({
            title: translate(props.lang, "deleteConfirmation"),
            text: translate(props.lang, "deleteMessage"),
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await removeUser(id)
                if (res.status === 'success') {
                    promptMessage(lang, 'success', res.message)
                    getList();
                } else {
                    promptMessage(lang, 'error', res.message ?? "error")
                    getList();
                }
            }
        })
    }

    return (
        <>
            <div className="card shadow">
                <div className="card-header">
                    <div>
                        <Link to="/user/create" className="btn btn-primary btn-sm">{translate(lang, 'addUser')}</Link>
                    </div>
                </div>
                <div className="card-body w-100">
                    <MuiTable list={{ ...list }} role={props.auth.auth.data.role} lang={props.lang} exportData={exportData} onTextSearched={(e) => onTextSearched(e)} onActionTaken={(e) => onActionTaken(e)}></MuiTable>
                    {/* <DataGrid rows={rows} columns={columns} /> */}
                    {/* <div id="job_data" className="dataTables_wrapper">
                        <CustomTable
                            lang={props.lang}
                            columns={tableColumns}
                            data={data}
                            excelExport={true}
                            fileData={fileData}
                        ></CustomTable>
                    </div> */}
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(UserList);